import Vue from "vue";
import VueRouter from "vue-router";
import { TablePlugin, PaginationPlugin, FormTagsPlugin, FormDatepickerPlugin, FormInputPlugin } from "bootstrap-vue";
import { FormSelectPlugin, FormCheckboxPlugin, FormTextareaPlugin, DropdownPlugin, SpinnerPlugin } from "bootstrap-vue";
import App from "./components/app.vue";
import CampaignTable from "./components/routes/campaign.vue";
import PlacementTable from "./components/routes/placement.vue";
import PlacementWeeksTable from "./components/routes/placement_week.vue";
import FormatTable from "./components/routes/format.vue";
import TraderTable from "./components/routes/trader.vue";
import CompanyTable from "./components/routes/company.vue";
import UserTable from "./components/routes/user.vue";
import Login from "./components/routes/login.vue";
import Register from "./components/routes/register.vue";
import FormattedNumInput from "./components/util/formatted_num_input.vue";
import { BFormTags } from "./components/util/b-form-tags.js";
import BFormTagTypeahead from "./components/util/tags-typeahead.vue";
import VSwitch from "./components/util/switch.js";
import SetPwd from "./components/routes/set-pwd.vue";

Vue.use(VueRouter);
Vue.use(TablePlugin);
Vue.use(PaginationPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormTagsPlugin);
Vue.use(FormDatepickerPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(DropdownPlugin);
Vue.use(SpinnerPlugin);

Vue.component("v-switch", VSwitch);
Vue.component("b-form-tags", BFormTags);
Vue.component("formatted-num-input", FormattedNumInput);
Vue.component("b-form-tag-typeahead", BFormTagTypeahead);

function getTablePropsFc(props) {
	return (route) => {
		const obj = {};
		for (const key in props) {
			if (key === "parent" || !route.query[key]) {
				continue;
			}
			const types = Array.isArray(props[key].type) ? props[key].type : [props[key].type];
			if (Array.isArray(route.query[key]) && types.includes(Array)) {
				if (types.includes(Number)) {
					obj[key] = route.query[key]
						.map((v) => (isNumber(v) ? Number(v) : v))
						.filter((v) => isNumber(v) || types.includes(String));
				} else {
					obj[key] = route.query[key];
				}
			} else if (isNumber(route.query[key]) && types.includes(Number)) {
				obj[key] = Number(route.query[key]);
			} else if (types.includes(String)) {
				obj[key] = route.query[key];
			}
		}
		return obj;
	};
}

const router = new VueRouter({
	mode: "history",
	linkActiveClass: "active",
	routes: [
		{ path: "/", alias: "", name: "hp", meta: {} },
		{ path: "/login", component: Login, name: "login", meta: {} },
		{ path: "/register", component: Register, name: "register", meta: {} },
		{ path: "/set-pwd/:token([^/?&]+)", component: SetPwd, name: "pwd", meta: {} },
		{ path: "/logout", name: "logout", meta: {} },
		{
			path: "/campaign",
			component: CampaignTable,
			name: "campaign",
			meta: {
				minRights: "ghost",
			},
			props: getTablePropsFc(CampaignTable.props),
		},
		{
			path: "/trader",
			component: TraderTable,
			name: "trader",
			meta: {
				minRights: "admin",
			},
			props: getTablePropsFc(TraderTable.props),
		},
		{
			path: "/company",
			component: CompanyTable,
			name: "company",
			meta: {
				minRights: "admin",
			},
			props: getTablePropsFc(CompanyTable.props),
		},
		{
			path: "/placement",
			component: PlacementTable,
			name: "placement",
			meta: {
				minRights: "admin",
			},
			props: getTablePropsFc(PlacementTable.props),
		},
		{
			path: "/placement/:id/plan",
			component: PlacementWeeksTable,
			name: "placementWeeks",
			meta: {
				minRights: "ghost",
			},
			props: getTablePropsFc(PlacementWeeksTable.props),
		},
		{
			path: "/format",
			component: FormatTable,
			name: "format",
			meta: {
				minRights: "admin",
			},
			props: getTablePropsFc(FormatTable.props),
		},
		{
			path: "/user",
			component: UserTable,
			name: "user",
			meta: {
				minRights: "admin",
			},
			props: getTablePropsFc(UserTable.props),
		},
	],
});

async function fetchApi(url, options, noMsg) {
	if (url.indexOf("/api") === -1) {
		url = "/api" + url;
	}
	return fetch(url, options)
		.then((response) => {
			if (response.status == 401) {
				localStorage.removeItem("username");
				localStorage.removeItem("userId");
				localStorage.removeItem("userRole");
			}
			if (this && !noMsg) {
				this.$root.msg = null;
				if ((options && options.method !== "GET") || response.status > 204) {
					let type;
					let val = response.statusText;
					switch (response.status) {
						case 200:
							if (val === "OK") {
								val = "Uloženo";
							}
							type = "alert-success";
							break;
						case 204:
							val = "Žádné položky.";
							type = "alert-success";
							break;
						case 304:
							val = "Nic se nezměnilo.";
							type = "alert-warning";
							break;
						default:
							type = "alert-danger";
							break;
					}
					if (response.status < 305) {
						this.$root.msg = {
							value: val,
							type,
						};
					}
				}
			}
			if (response.status > 304) {
				throw response;
			} else if (response.status === 204) {
				return;
			}

			const contentType = response.headers.get("Content-type") || "";
			if (contentType.indexOf("json") > -1) {
				return response.json();
			} else if (contentType.indexOf("text") > -1) {
				return response.text();
			}
			return response;
		})
		.catch((err) => {
			if (this && !noMsg && (err.message || err.statusText || err)) {
				let msg = err.message || err.statusText || err;
				if (err && err.status === 401 && this && this.$router) {
					if (this.$router.currentRoute.path === "/login") {
						msg = "Špatné přihlašovací údaje.";
					} else {
						this.$router.replace("/login");
					}
				}
				this.$root.msg = {
					value: msg,
					type: "alert-danger",
				};
			}
			throw err;
		});
}

function loggedIn() {
	return !!localStorage.getItem("userId");
}

function getFormattedNumber(value) {
	return value
		? value
				.toString()
				.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
				.replace(".", ",")
		: "";
}

function hasRights(...roles) {
	if (!roles.length) {
		// nevyzaduje prava
		return true;
	}

	switch (localStorage.getItem("userRole")) {
		case "superadmin":
			return true;
		case "admin":
		case "accountant":
			return roles.indexOf("admin") > -1;
		case "trader":
			return roles.indexOf("trader") > -1;
		case "ghost":
			return roles.indexOf("ghost") > -1;
		default:
			return false;
	}
}

const roleValues = {
	superadmin: 4,
	admin: 3,
	accountant: 3,
	trader: 2,
	ghost: 1,
};

function hasMinRights(role) {
	if (!role) {
		// nevyzaduje prava
		return true;
	}
	return roleValues[role] <= roleValues[localStorage.getItem("userRole") || 0];
}

function isNumber(val) {
	if ((typeof val === "string" && !val) || typeof val === "boolean") {
		return false;
	}
	return !isNaN(val);
}

async function setPlannedPlacements() {
	if (this && loggedIn() && hasMinRights("ghost")) {
		return fetchApi(`/placement?capacity=1`)
			.then((data) => {
				if (data && data.rows) {
					this.$root.plannedPlacements = data.rows;
				} else {
					throw data;
				}
			})
			.catch((err) => {
				this.$root.plannedPlacements = [];
			});
	}
}

Vue.mixin({
	methods: {
		loggedIn,
		hasRights,
		hasMinRights,
		fetchApi,
		isNumber,
		getFormattedNumber,
		setPlannedPlacements,
	},
});

const campaignRoute = router.resolve({ name: "campaign" }).route;

router.beforeEach((to, from, next) => {
	const logged = loggedIn();
	const self = router.app;
	if (logged && !self.$root.plannedPlacements) {
		setPlannedPlacements.call(self);
	}
	if (logged && !self.$root.username) {
		self.$root.username = localStorage.getItem("username");
	}
	self.$root.showNav = false;
	if (to.name === "pwd") {
		return fetchApi(
			to.path,
			{
				credentials: "include",
			},
			true
		)
			.then((err) => next())
			.catch((err) => next({ name: "login" }));
	}
	switch (to.path) {
		case "/register":
			return logged ? next({ name: "login" }) : next();
		case "/login":
			return logged ? next({ name: "campaign" }) : next();
		case "/logout":
			if (logged) {
				return fetchApi("/logout", {
					credentials: "include",
				})
					.then((response) => {
						localStorage.removeItem("username");
						localStorage.removeItem("userId");
						localStorage.removeItem("userRole");
						self.$root.plannedPlacements = null;
						return next({ name: "login" });
					})
					.catch((err) => {
						console.error(err.message || err.statusText || err);
						return next({ name: "hp" });
					});
			}
			break;
		default:
			if (!logged) {
				return next({
					name: "login",
					query: { redirect: to.fullPath },
				});
			}

			if (to.name === "hp") {
				if (hasMinRights(campaignRoute.meta.minRights)) {
					return next({
						name: "campaign",
					});
				}
				return next();
			}

			if (!hasMinRights(to.meta.minRights)) {
				self.$root.msg = {
					value: "Nemáte práva",
					type: "text-danger",
				};
				return next({
					name: "hp",
				});
			}
			return next();
	}
});

/* eslint-disable no-new */
new Vue({
	data: {
		msg: null,
		showNav: false,
		print: false,
		plannedPlacements: null,
		logo: import.meta.env.VITE_LOGO,
	},
	el: "#app",
	router,
	// replace the content of <div id="app"></div> with App
	render: (h) => h(App),
});
