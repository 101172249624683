<template>
	<b-modal id="format-modal"
		size="xl"
		:visible="visible"
		@hide="$emit('hide', $event)"
		@hidden="$emit('hidden')"
		dialog-class="container-fluid"
		centered>
		<template v-slot:modal-header="{ close }">
			<h5 v-if="item && item.id" class="text-wrap mb-0">Formát: {{ item.description }} (id:{{ item.id }})</h5>
			<h5 v-else class="mb-0">Nový formát</h5>
			<button type="button" aria-label="Zavřít" class="close" @click="close()">×</button>
		</template>
		<template v-slot:modal-footer="{ close, cancel }">
			<button v-if="removeItem && isSuperAdmin"
				class="btn btn-outline-danger mr-auto"
				type="button"
				@click="deleteItem('format', item, close, moveTo)">
				Smazat
			</button>
			<button class="btn btn-outline-primary" type="button" @click="cancel()">Zavřít</button>
			<button v-if="!removeItem || !isSuperAdmin" class="btn btn-primary" type="button" @click="submitItem('format', item, false, close)">Uložit</button>
		</template>	
		<div v-if="removeItem && isSuperAdmin" class="form-row">
			<div class="form-group col-12 col-md-2 col-xl-2">
				<label class="mb-1" :for="getInputId(item, 'move-to')">Sloučit do:</label>
				<b-form-tag-typeahead
					v-model="moveTo"
					:format-value="(obj) => obj.description"
					:id="getInputId(item, 'move-to')"
					:search-provider="searchModelProvider('format', item.id)"
					:limit="1"
					size="sm"
					placeholder="Zadejte název.."
					@remove="moveTo = null"
					@add="moveTo = $event">
				</b-form-tag-typeahead>
			</div>
		</div>
		<div v-else class="form-row">
			<div class="form-group col-12">
				<label class="mb-1" :for="getInputId(item, 'description')">Název:</label>
				<b-form-input v-model="item.$inputs.description"
					placeholder="Zadejte název.."
					:id="getInputId(item, 'description')"
					size="sm"
					:state="item.$states.description"
					@input="getDescValidator('format', item)($event)"
					@blur="onTextBlur(item, 'description')"
					:required="true">
				</b-form-input>
				<div class="invalid-tooltip truncate" v-if="item.$errors.description">{{ item.$errors.description }}</div>
			</div>
		</div>
	</b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import formatMixin from '../mixins/format_placement_mixin';
import inputMixin from '../mixins/input_mixin';
export default {
	components: {
		BModal
	},
	data() {
		return Object.assign(this.inputData(), {
			moveTo: null,
			required: ['description'],
			descriptions: this.parent.description ? [this.parent.description] : [],
			item: this.setItemContext(Object.assign({}, this.parent), {
				description: null
			})
		});
	},
	mixins: [inputMixin, formatMixin],
	props: {
		parent: {
			type: Object,
			default: () => { return {} }
		},
		visible: {
			type: Boolean,
			default: false
		},
		removeItem: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		descriptionValidator(tag) {
			// kontrola validity aliasu
			return tag && tag.lenght > 2
		},
		checkDescriptionExists(val) {
			return this.fetchApi(`/format?description=${encodeURI(val)}`)
				.then(data => {
					if (data && data.rows) {
						this.item.$errors.description = 'Formát už existuje.'; // neprojde validatorem takze zcervena
						return '';
					}
					return val;
				})
				.catch(err => {
					err = err.message||err.statusText||err;
					this.item.$errors.description = 'Error:' + err; // pro jistotu aby neproslo validatorem
					return '';
				});
		}
	}
}
</script>
