<template>
	<b-modal id="company-modal"
		size="xl"
		:visible="visible"
		@hide="$emit('hide', $event)"
		@hidden="$emit('hidden')"
		dialog-class="container-fluid"
		centered>
		<template v-slot:modal-header="{ close }">
			<h5 v-if="item && item.id" class="text-wrap mb-0">Firma: {{ item.name }} (id:{{ item.id }})</h5>
			<h5 v-else class="mb-0">Nová firma</h5>
			<button type="button" aria-label="Zavřít" class="close" @click="close()">×</button>
		</template>
		<template v-slot:modal-footer="{ close, cancel }">
			<button v-if="removeItem && isSuperAdmin"
				class="btn btn-outline-danger mr-auto"
				type="button"
				@click="deleteItem('company', item, close, moveTo)">
				Smazat
			</button>
			<button class="btn btn-outline-primary" type="button" @click="cancel()">Zavřít</button>
			<button v-if="!removeItem || !isSuperAdmin"
				class="btn btn-primary"
				type="button"
				@click="submitItem('company', item, false, close)">
				Uložit
			</button>
		</template>
		<div v-if="removeItem && isSuperAdmin" class="form-row">
			<div class="form-group col-12 col-md-2 col-xl-2">
				<label class="mb-1" :for="getInputId(item, 'move-to')">Sloučit do:</label>
				<b-form-tag-typeahead
					v-model="moveTo"
					:format-value="(obj) => obj.name"
					:id="getInputId(item, 'move-to')"
					:search-provider="searchModelProvider('company', item.id)"
					:limit="1"
					size="sm"
					placeholder="Zadejte název.."
					@remove="moveTo = null"
					@add="moveTo = $event">
				</b-form-tag-typeahead>
			</div>
		</div>	
		<div v-else class="form-row">
			<div class="form-group col-12">
				<label class="mb-1" :for="getInputId(item, 'name')">Jméno:</label>
				<b-form-input v-model="item.$inputs.name"
					placeholder="Zadejte název.."
					:id="getInputId(item, 'name')"
					size="sm"
					:state="item.$states.name"
					@input="onNameInput(item, $event)"
					@focus="onToggleNameFocus(item, true)"
					@blur="onToggleNameFocus(item, false)"
					:required="required.indexOf('name') > -1">
				</b-form-input>
				<div class="dropdown b-dropdown">
					<ul role="menu" tabindex="-1"
						:class="`dropdown-menu w-100 ${item.$nameFocused && item.$inputs.name && (item.$nameItems.length||item.$loading) ? ' show' : ''}`"
						@focus="onToggleNameFocus(item, true)"
						@blur="onToggleNameFocus(item, false)">
						<li role="presentation" class="text-center" v-if="!item.$nameItems.length||item.$loading">
							<b-spinner small variant="secondary" label="Načítám" v-if="item.$loading"></b-spinner>
							<span class="b-dropdown-text" v-else>Žádné výsledky</span>
						</li>
						<li role="presentation" class="small" v-show="!item.$loading" v-for="option in item.$nameItems" :key="option.ico">
							<button role="menuitem" type="button" class="dropdown-item text-wrap" v-on:click="onSelectedOption(item, option)">
								<span class="truncate" v-html="boldSearchSubstring(item.$inputs.name, getFormatOption(option))"></span>
							</button>
						</li>
					</ul>
				</div>
				<div class="invalid-tooltip truncate" v-if="item.$errors.name">{{ item.$errors.name }}</div>
			</div>
			<div class="form-group col-12 col-md-3 col-xl-2">
				<label class="mb-1" :for="getInputId(item, 'ico')">IČO:</label>
				<b-form-input v-model="item.$inputs.ico"
					size="sm"
					placeholder="Zadejte IČO.."
					:id="getInputId(item, 'ico')"
					:state="item.$states.ico"
					@blur="checkUidExists(item, 'ico')"
					@input="uidValidator(item, $event, 'ico', validateICO)"
					:required="required.indexOf('ico') > -1">
				</b-form-input>
				<div class="invalid-tooltip truncate" v-if="item.$errors.ico">{{ item.$errors.ico }}</div>
			</div>
			<div class="form-group col-12 col-md-3 col-xl-2">
				<label class="mb-1" :for="getInputId(item, 'ico')">DIČ/VAT:</label>
				<b-form-input v-model="item.$inputs.dic"
					size="sm"
					placeholder="Zadejte DIČ/VAT.."
					:id="getInputId(item, 'dic')"
					:state="item.$states.dic"
					@blur="checkUidExists(item, 'dic')"
					@input="uidValidator(item, $event, 'dic', validateDIC)"
					:required="required.indexOf('dic') > -1">
				</b-form-input>
				<div class="invalid-tooltip truncate" v-if="item.$errors.ico">{{ item.$errors.ico }}</div>
			</div>
			<div class="form-group col-12 col-md-7 col-xl-8">
				<label class="mb-1" :for="getInputId(item, 'address')">Adresa:</label>
				<b-form-input v-model="item.$inputs.address"
					size="sm"
					placeholder="Zadejte adresu.."
					:id="getInputId(item, 'address')"
					:state="item.$states.address"
					@input="onTextInput(item, 'address', $event)"
					@blur="onTextBlur(item, 'address')"
					:required="required.indexOf('address') > -1">
				</b-form-input>
				<div class="invalid-tooltip truncate" v-if="item.$errors.address">{{ item.$errors.address }}</div>
			</div>
			<div class="form-group col-12 col-md-2 col-xl-2">
				<label class="mb-1" :for="getInputId(item, 'item')">Ověřená:</label>
				<b-form-checkbox v-model="item.$inputs.valid"
					class="success-checkbox d-inline pr-0"
					:id="getInputId(item, 'valid')"
					title="Správnost údajů ověřena (např. ičo)"
					@change="item.$errors.valid = !!item.valid !== !!item.$inputs.valid ? '' : null"
					size="lg">
				</b-form-checkbox>
			</div>
		</div>
	</b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import companyMixin from '../mixins/company_mixin';
import inputMixin from '../mixins/input_mixin';

export default {
	components: {
		BModal
	},
	mixins: [inputMixin, companyMixin],
	data() {
		return Object.assign(this.inputData(), {
			moveTo: null,
			modelType: 'company',
			required: ['name'],
			item: this.setItemContext(Object.assign({}, this.parent), {
				name: null,
				ico: null,
				dic: null,
				address: null,
				valid: false
			}),
		})
	},
	props: {
		parent: {
			type: Object,
			default: () => { return {} }
		},
		visible: {
			type: Boolean,
			default: false
		},
		removeItem: {
			type: Boolean,
			default: false
		},
	}
}
</script>
