<template>
	<div v-click-outside="onBlur">
		<b-form-tags v-model="tagStrings"
			:id="id"
			ref="formTags"
			:placeholder="placeholder"
			:limit="limit"
			:state="tagState"
			:size="size"
			:disabled="disabled"
			:required="required"
			@focus="onFocus()"
			@ht="onInput"
			:on-remove-tag="onRemoveTag"
			no-feedback
			:no-add-on-enter="onlySelected"
			:no-add-button="onlySelected">
		</b-form-tags>
		<!-- vue b-dropdown nema moznost navesit focus/blur na menu - bez toho neprobehne click -->
		<div class="dropdown b-dropdown">
			<ul role="menu" tabindex="-1"
				:class="`dropdown-menu w-100 ${typeaheadFocused && searchVal ? ' show' : ''}`">
				<li v-if="searchVal && (!items.length||loading)" role="presentation" class="text-center">
					<b-spinner small variant="secondary" label="Načítám" v-if="loading"></b-spinner>
					<span class="b-dropdown-text" v-else>Žádné výsledky</span>
				</li>
				<template v-else v-for="option in items">
					<li role="presentation" class="small">
						<button role="menuitem" type="button" class="dropdown-item text-truncate" v-on:click="onTagClick(option)">
							<span v-html="boldSearchString(searchVal, getFormatOption(option))"></span>
						</button>
					</li>
				</template>
			</ul>
		</div>
	</div>
</template>

<script>
import tagsTypeaheadMixin from '../mixins/tags-typeahead_mixin';
export default {
	mixins: [tagsTypeaheadMixin]
}
</script>