<template>
	<b-modal id="trader-modal"
		size="xl"
		:visible="visible"
		@hide="$emit('hide', $event)"
		@hidden="$emit('hidden')"
		dialog-class="container-fluid"
		centered>
		<template v-slot:modal-header="{ close }">
			<h5 v-if="item && item.id" class="text-wrap mb-0">Obchodník: {{ getName(item) }} (id:{{ item.id }})</h5>
			<h5 v-else class="mb-0">Nový obchodník</h5>
			<button type="button" aria-label="Zavřít" class="close" @click="close()">×</button>
		</template>
		<template v-slot:modal-footer="{ close, cancel }">
			<button v-if="removeItem && isSuperAdmin"
				class="btn btn-outline-danger mr-auto"
				type="button"
				@click="deleteItem('trader', item, close, moveTo)">
				Smazat
			</button>
			<button class="btn btn-outline-primary" type="button" @click="cancel()">Zavřít</button>
			<button v-if="!removeItem || !isSuperAdmin"
				class="btn btn-primary"
				type="button"
				@click="submitItem('trader', item, false, close)">
				Uložit
			</button>
		</template>
		<div v-if="removeItem && isSuperAdmin" class="form-row">
			<div class="form-group col-12 col-md-2 col-xl-2">
				<label class="mb-1" :for="getInputId(item, 'move-to')">Sloučit do:</label>
				<b-form-tag-typeahead
					v-model="moveTo"
					:format-value="getName"
					:id="getInputId(item, 'move-to')"
					:search-provider="searchModelProvider('trader', item.id)"
					:limit="1"
					size="sm"
					placeholder="Zadejte název.."
					@remove="moveTo = null"
					@add="moveTo = $event">
				</b-form-tag-typeahead>
			</div>
		</div>	
		<div v-else class="form-row">
			<div class="form-group col-12 col-md-4 col-xl-2">
				<label class="mb-1" :for="getInputId(item, 'alias')">Alias:</label>
				<div class="position-relative">
					<b-form-input v-model="item.$inputs.alias"
						placeholder="Zadejte alias.."
						:id="getInputId(item, 'alias')"
						size="sm"
						:state="item.$states.alias"
						@input="getAliasvalidator(item)($event)"
						:required="required.indexOf('alias') > -1">
					</b-form-input>
					<b-spinner small variant="secondary" label="Načítám" v-if="item.$loading" style="position: absolute;right: 8px;top: 8px;opacity: 0.5;"></b-spinner>
				</div>
			</div>
			<div class="form-group col-12 col-md-8 col-xl-10">
				<label class="mb-1" :for="getInputId(item, 'name')">Jméno:</label>
				<b-form-input v-model="item.$inputs.name"
					size="sm"
					placeholder="Zadejte jméno.."
					:id="getInputId(item, 'name')"
					:state="item.$states.name"
					@input="onTextInput(item, 'name', $event)"
					@blur="onTextBlur(item, 'name')">
				</b-form-input>
			</div>
		</div>
	</b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import inputMixin from '../mixins/input_mixin';
import traderMixin from '../mixins/trader_mixin';
export default {
	components: {
		BModal
	},
	data() {
		return Object.assign(this.inputData(), {
			moveTo: null,
			required: ['alias'],
			item: this.setItemContext(Object.assign({}, this.parent), {
				alias: null,
				name: null,
			})
		});
	},
	mixins: [inputMixin, traderMixin],
	props: {
		parent: {
			type: Object,
			default: () => { return {} }
		},
		visible: {
			type: Boolean,
			default: false
		},
		removeItem: {
			type: Boolean,
			default: false
		},
	}
}
</script>
