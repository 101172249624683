import { debounce } from "lodash";

export default {
	methods: {
		getDescValidator(modelType, item) {
			return val => {
				// kontrola validity aliasu
				this.onTextInput(item, "description", val, true);
				if (item.$errors.description) {
					return;
				}

				if (item.$states.description !== null) {
					item.$loading = true;
					this.debouncedCheckDescExists(modelType, item);
				}
			};
		},
		debouncedCheckDescExists: debounce(async function(modelType, item) {
			if (item.$states.description !== false && item.description !== item.$inputs.description) {
				await this.fetchApi(`/${modelType}?description=${encodeURI(item.$inputs.description)}`)
					.then(data => {
						if (data && data.rows && !data.rows.some(t => t.id === item.id)) {
							// unicode_ci skipuje pri porovnani diakritiku
							throw new Error(
								`Podobný název už existuje("${data.rows[0].description}"). Zvolte jiný název nebo použijte existujicí položku.`
							); // neprojde validatorem takze zcervena
						}
						item.$states.description = true;
						item.$errors.description = "";
					})
					.catch(err => {
						item.$errors.description = err.message || err.statusText || err; // pro jistotu aby neproslo validatorem
						item.$states.description = false;
						return;
					});
			}
			item.$loading = false;
		}, 1000)
	}
};
