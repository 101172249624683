import { debounce } from "lodash";

const inputs = {
	date_to: null,
	date_from: null,
	impression: null,
	placements_campaigns_id: null
};

export default {
	props: {
		week: {
			type: Object,
			default: () => {
				return {};
			}
		},
		placement: {
			type: Object,
			default: () => {
				return {};
			}
		}
	},
	methods: {
		setDateFromMinMax(item) {
			item.$minDateFrom = new Date(
				Math.max(this.week.start, item.$campaign ? item.$campaign.$dateFrom : this.week.start)
			);
			item.$maxDateFrom = new Date(
				Math.min(
					item.$dateTo || this.week.end,
					this.week.end,
					item.$campaign ? item.$campaign.$dateTo : this.week.end
				)
			);
		},
		setDateToMinMax(item) {
			item.$minDateTo = new Date(
				Math.max(
					item.$dateFrom || this.week.start,
					this.week.start,
					item.$campaign ? item.$campaign.$dateFrom : this.week.start
				)
			);
			item.$maxDateTo = new Date(
				Math.min(this.week.end, item.$campaign ? item.$campaign.$dateTo : this.week.end)
			);
		},
		planData() {
			return {
				campaignOptions: this.week.campaigns.map(c => ({
					value: c.$placements_campaigns_id,
					text: c.$label
				})),
				dateFormat: { year: "numeric", month: "numeric", day: "numeric" },
				required: ["date_from", "date_to", "impression", "placements_campaigns_id"],
				inputs
			};
		},
		onCampaignChange(item) {
			item.$campaign = this.week.campaigns.find(
				c => Number(item.$inputs.placements_campaigns_id) === c.$placements_campaigns_id
			);
			if (!item.$inputs.placements_campaigns_id || !item.$campaign) {
				item.$errors.placements_campaigns_id = item.$inputs.placements_campaigns_id
					? "Nevalidní kampaň."
					: "Toto pole je povinné.";
				item.$states.placements_campaigns_id = false;
				return;
			}
			if (Number(item.$inputs.placements_campaigns_id) === item.placements_campaigns_id) {
				item.$errors.placements_campaigns_id = null;
				item.$states.placements_campaigns_id = null;
				return;
			}
			for (const key in item.$inputs) {
				// reset other inputs
				if (key === "placements_campaigns_id") {
					continue;
				}
				item.$inputs[key] = null;
				item.$states[key] = null;
				item.$errors[key] = null;
			}
			this.setDateFromMinMax(item);
			this.setDateToMinMax(item);
			item.$errors.placements_campaigns_id = "";
			item.$states.placements_campaigns_id = true;
		},
		dateFromToValidator(item, key) {
			const alias = key === "date_from" ? "$dateFrom" : "$dateTo";
			this.dateValidator(item, key);
			if (item.$states[key] !== false && item.$inputs[key]) {
				item[alias] = new Date(item.$inputs[key] + "T12:00:00+00:00");
				if (
					(item.$campaign && key === "date_to" && item[alias] > item.$campaign[alias]) ||
					(key === "date_from" && item[alias] < item.$campaign[alias])
				) {
					item[alias] = null;
					item.$errors[key] = "Datum je mimo rozmezí kampaně.";
					item.$states[key] = false;
				}
				return;
			} else {
				item[alias] = null;
			}
			this.setDateFromMinMax(item);
			this.setDateToMinMax(item);
		},
		setPlanItemContext(item) {
			const newItem = this.setItemContext(
				Object.assign(
					{
						$campaign: null,
						$dateTo: null,
						$dateFrom: null,
						$minDateFrom: null,
						$minDateTo: null,
						$maxDateFrom: null,
						$maxDateTo: null
					},
					item
				),
				inputs
			);
			this.setDateFromMinMax(newItem);
			this.setDateToMinMax(newItem);
			return newItem;
		}
	}
};
