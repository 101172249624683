<template>
	<b-modal
		id="campaign-modal"
		size="xl"
		:visible="visible"
		@hide="modalClose($event, item)"
		@hidden="$emit('hidden')"
		dialog-class="container-fluid"
		centered
	>
		<template v-slot:modal-header="{ close }">
			<h5 v-if="item && item.id" class="text-wrap mb-0">
				<svg
					v-if="isLocked"
					class="align-top"
					enable-background="new 0 0 24 24"
					height="21"
					viewBox="0 0 24 24"
					width="21"
					xmlns="http://www.w3.org/2000/svg"
					fill="grey"
					title="Uzamčená kampaň"
				>
					<path
						d="m18.75 9h-.75v-3c0-3.309-2.691-6-6-6s-6 2.691-6 6v3h-.75c-1.24 0-2.25 1.009-2.25 2.25v10.5c0 1.241 1.01 2.25 2.25 2.25h13.5c1.24 0 2.25-1.009 2.25-2.25v-10.5c0-1.241-1.01-2.25-2.25-2.25zm-10.75-3c0-2.206 1.794-4 4-4s4 1.794 4 4v3h-8zm5 10.722v2.278c0 .552-.447 1-1 1s-1-.448-1-1v-2.278c-.595-.347-1-.985-1-1.722 0-1.103.897-2 2-2s2 .897 2 2c0 .737-.405 1.375-1 1.722z"
					/>
				</svg>
				Kampaň: {{ item.name }} (id:{{ item.id }})
			</h5>
			<h5 v-else class="mb-0">Nová kampaň</h5>
			<button type="button" aria-label="Zavřít" class="close" @click="close()">×</button>
		</template>
		<template v-slot:modal-footer="{ close, cancel }">
			<button
				v-if="isSuperAdmin"
				class="btn btn-outline-danger mr-auto"
				type="button"
				@click="deleteItem('campaign', item, close)"
			>
				Smazat
			</button>
			<button class="btn btn-outline-primary" type="button" @click="cancel()">Zavřít</button>
			<button v-if="canSubmit" class="btn btn-primary" type="button" @click="onSubmit(item, close)">
				Uložit
			</button>
		</template>
		<div class="form-row" v-if="item">
			<div class="form-group col-12 col-md-3 col-xl-2">
				<label class="mb-1" :for="getInputId(item, 'invoice_month')">Období:</label>
				<b-form-datepicker
					v-model="item.$inputs.invoice_month"
					type="date"
					placeholder="1. v měsíci.."
					:id="getInputId(item, 'invoice_month')"
					size="sm"
					:date-format-options="monthFormat"
					@input="dateValidator(item, 'invoice_month', true)"
					:state="item.$states.invoice_month"
					:disabled="!canEdit"
					:required="required.indexOf('invoice_month') > -1"
					locale="cs"
					label-reset-button="Smazat"
					label-close-button="Zavřít"
					start-weekday="1"
					reset-button
					close-button
				>
				</b-form-datepicker>
				<div class="invalid-tooltip truncate" v-if="item.$errors.invoice_month">
					{{ item.$errors.invoice_month }}
				</div>
			</div>
			<div class="form-group col-12 col-md-2 col-xl-2">
				<label class="mb-1" :for="getInputId(item, 'trader')">Account:</label>
				<b-form-tag-typeahead
					v-model="item.trader"
					:format-value="(t) => `${t.alias}${t.name ? ` (${t.name})` : ''}`"
					:id="getInputId(item, 'trader')"
					object-key="alias"
					:search-provider="searchAdapter('trader')"
					:state="item.$states.trader"
					:required="required.indexOf('trader') > -1"
					size="sm"
					placeholder="Zadejte alias.."
					@remove="onRemoveOneToManyRelation('trader', item, $event)"
					@add="onAddOneToManyRelation('trader', item, $event)"
					:disabled="!canEdit"
				>
				</b-form-tag-typeahead>
				<!-- z nejakeho duvodu nereaktivni i kdyz je trader_id v $errors datech od zacatku -->
				<div class="invalid-tooltip truncate" v-if="item.$errors.trader">{{ item.$errors.trader }}</div>
			</div>
			<div class="form-group col-12 col-md-2 col-xl-2">
				<label class="mb-1" :for="getInputId(item, 'type')">Typ:</label>
				<b-form-select
					v-model="item.$inputs.type"
					:id="getInputId(item, 'type')"
					:options="typeOptions"
					:disabled="!canEdit || (!isSuperAdmin && item.$inputs.type === 'proforma')"
					@change="onSelectChange(typeOptions)"
					:state="item.$states.type"
					class="d-block"
					size="sm"
					style="font-size: 13px"
					:required="required.indexOf('type') > -1"
				>
				</b-form-select>
				<div class="invalid-tooltip truncate" v-if="item.$errors.type">{{ item.$errors.type }}</div>
			</div>
			<div class="form-group col-12 col-md-6 col-xl-3">
				<label class="mb-1" :for="getInputId(item, 'agency')">Agentura:</label>
				<b-form-tags
					v-model="agencyNames"
					:id="getInputId(item, 'agency')"
					placeholder="Zadejte IČO/DIČ.."
					:format-tag="getCompanyAddTag('agency')"
					:tag-validator="getCompanyTagValidator('agency')"
					invalid-tag-text="Nevalidní"
					add-button-text="Přidat"
					:limit="1"
					:state="item.$states.agency"
					@tag-state="
						(v) => (item.$states.agency = v.length ? item.$states.agency : item.agency ? true : null)
					"
					@input="onCompanyInput($event, 'agency', false)"
					size="sm"
					:disabled="!canEdit"
					:required="required.indexOf('agency') > -1"
					no-feedback
				>
				</b-form-tags>
				<div class="invalid-tooltip truncate" v-if="item.$errors.agency">{{ item.$errors.agency }}</div>
			</div>
			<div class="form-group col-12 col-md-6 col-xl-3">
				<label class="mb-1" :for="getInputId(item, 'client')">Klient:</label>
				<b-form-tags
					v-model="clientNames"
					:id="getInputId(item, 'client')"
					placeholder="Zadejte IČO/DIČ.."
					:format-tag="getCompanyAddTag('client')"
					invalid-tag-text="Nevalidní"
					add-button-text="Přidat"
					size="sm"
					:disabled="!canEdit"
					:limit="1"
					:state="item.$states.client"
					:tag-validator="getCompanyTagValidator('client', true)"
					@tag-state="(v) => (item.$states.client = v.length ? item.$states.client : false)"
					@input="onCompanyInput($event, 'client', true)"
					:required="required.indexOf('client') > -1"
					no-feedback
				>
				</b-form-tags>
				<div class="invalid-tooltip truncate" v-if="item.$errors.client">{{ item.$errors.client }}</div>
			</div>
			<div class="form-group col-12 col-xl-9">
				<label class="mb-1" :for="getInputId(item, 'name')">Název:</label>
				<b-form-input
					v-model="item.$inputs.name"
					size="sm"
					placeholder="Zadejte název.."
					:id="getInputId(item, 'name')"
					:state="item.$states.name"
					@input="
						onGenericInput(item, 'name', true, () =>
							item.$inputs.name.length < 3 ? 'Zadejte alespoň 3 znaky' : ''
						)
					"
					:required="required.indexOf('name') > -1"
					:disabled="!canEdit"
				>
				</b-form-input>
				<div class="invalid-tooltip truncate" v-if="item.$errors.name">{{ item.$errors.name }}</div>
			</div>
			<div class="form-group col-12 col-md-6 col-xl-3">
				<label class="mb-1" :for="getInputId(item, 'format')">Formát:</label>
				<b-form-tag-typeahead
					v-model="item.format"
					:format-value="(f) => f.description"
					:id="getInputId(item, 'format')"
					:search-provider="searchAdapter('format')"
					object-key="description"
					:required="required.indexOf('format') > -1"
					:state="item.$states.format"
					size="sm"
					placeholder="Název.."
					@remove="onRemoveOneToManyRelation('format', item, $event)"
					@add="onAddOneToManyRelation('format', item, $event)"
					:disabled="!canEdit"
				>
				</b-form-tag-typeahead>
				<div class="invalid-tooltip truncate" v-if="item.$errors.format">{{ item.$errors.format }}</div>
			</div>
			<div class="form-group col-12 col-md-6 col-xl-4">
				<label class="mb-1" :for="getInputId(item, 'placements')">Umístění:</label>
				<b-form-tag-typeahead
					v-model="item.placements"
					:format-value="(t) => t.description"
					:id="getInputId(item, 'placements')"
					:search-provider="searchAdapter('placements')"
					object-key="description"
					:required="required.indexOf('placements') > -1"
					:state="item.$states.placements"
					size="sm"
					:limit="5"
					placeholder="Název.."
					@remove="onRemoveManyToManyRelation('placements', item, $event)"
					@add="onAddManyToManyRelation('placements', item, $event)"
					:disabled="!canEdit"
				>
				</b-form-tag-typeahead>
				<div class="invalid-tooltip truncate" v-if="item.$errors.placements">{{ item.$errors.placements }}</div>
			</div>
			<div class="form-group col-12 col-md-3 col-xl-2">
				<label class="mb-1" :for="getInputId(item, 'date_from')">Datum od:</label>
				<b-form-datepicker
					v-model="item.$inputs.date_from"
					type="date"
					placeholder="Datum od.."
					:id="getInputId(item, 'date_from')"
					size="sm"
					locale="cs"
					:date-format-options="dateFormat"
					:required="required.indexOf('date_from') > -1"
					:max="item.$inputs.date_to"
					@input="dateValidator(item, 'date_from')"
					:state="item.$states.date_from"
					:disabled="!canEdit"
					label-reset-button="Smazat"
					label-close-button="Zavřít"
					start-weekday="1"
					reset-button
					close-button
				>
				</b-form-datepicker>
				<div class="invalid-tooltip truncate" v-if="item.$errors.date_from">{{ item.$errors.date_from }}</div>
			</div>
			<div class="form-group col-12 col-md-3 col-xl-2">
				<label class="mb-1" :for="getInputId(item, 'date_to')">Datum do:</label>
				<b-form-datepicker
					v-model="item.$inputs.date_to"
					type="date"
					placeholder="Datum do.."
					:id="getInputId(item, 'date_to')"
					size="sm"
					locale="cs"
					:min="item.$inputs.date_from"
					:required="required.indexOf('date_to') > -1"
					:date-format-options="dateFormat"
					@input="dateValidator(item, 'date_to')"
					:state="item.$states.date_to"
					:disabled="!canEdit"
					label-reset-button="Smazat"
					label-close-button="Zavřít"
					start-weekday="1"
					reset-button
					close-button
				>
				</b-form-datepicker>
				<div class="invalid-tooltip truncate" v-if="item.$errors.date_to">{{ item.$errors.date_to }}</div>
			</div>
			<div class="form-group col-12 col-md-2 col-xl-1">
				<label class="mb-1" :for="getInputId(item, 'bonus')">Bonus:</label>
				<formatted-num-input
					v-model="item.$inputs.bonus"
					placeholder="0%"
					:id="getInputId(item, 'bonus')"
					size="sm"
					suffix="%"
					:state="item.$states.bonus"
					@input="onGenericInput(item, 'bonus', false)"
					:readOnly="!canEdit"
				>
				</formatted-num-input>
				<div class="invalid-tooltip truncate" v-if="item.$errors.bonus">{{ item.$errors.bonus }}</div>
			</div>
			<div class="form-group col-12 col-md-4 col-xl-3">
				<label class="font-weight-bold mb-1" :for="getInputId(item, 'price')">Cena bez DPH:</label>
				<formatted-num-input
					v-model="item.$inputs.price"
					placeholder="Zadejte částku.."
					class="font-weight-bold"
					size="sm"
					:id="getInputId(item, 'price')"
					suffix=" Kč"
					:state="item.$states.price"
					@input="onGenericInput(item, 'price', true)"
					:readOnly="!canEdit"
					:required="required.indexOf('price') > -1"
				>
				</formatted-num-input>
				<div class="invalid-tooltip truncate" v-if="item.$errors.price">{{ item.$errors.price }}</div>
			</div>
			<div class="form-group col-12">
				<label class="mb-1" :for="getInputId(item, 'description')">Poznámka:</label>
				<b-form-textarea
					v-model="item.$inputs.description"
					:id="getInputId(item, 'description')"
					:state="item.$states.description"
					placeholder="Zadejte popis"
					:required="required.indexOf('description') > -1"
					@input="
						onGenericInput(item, 'description', false, () =>
							item.$inputs.description.length > 255 ? 'Text je moc dlouhý' : ''
						)
					"
					max-length="255"
					:disabled="!canEdit"
					size="sm"
				></b-form-textarea>
				<div class="invalid-tooltip truncate" v-if="item.$errors.description">
					{{ item.$errors.description }}
				</div>
			</div>
			<div class="form-group col-12 col-md-6">
				<label class="mb-1" :for="getInputId(item, 'mediaplanFile')">Podklad pro fakturaci (Médiaplán):</label>
				<div v-if="!item.files || !item.files.some((f) => f.type === 'mediaplan')">
					<b-form-file
						:id="getInputId(item, 'mediaplanFile')"
						v-model="item.$inputs.$mediaplanFile"
						accept=".pdf, .xls, .xlsx"
						class="d-block"
						:required="!isAdmin && item.$inputs.type === 'order'"
						@input="onFileInput(item, 'mediaplan', !isAdmin && item.$inputs.type === 'order')"
						:state="item.$states.$mediaplanFile"
						:disabled="!canEdit"
						placeholder="Vyberte nebo přesuňte soubor..."
						drop-placeholder="Přesuňte sem..."
					>
					</b-form-file>
					<div class="invalid-tooltip truncate" v-if="item.$errors.$mediaplanFile">
						{{ item.$errors.$mediaplanFile }}
					</div>
				</div>
				<div v-else>
					<div class="file-badge badge badge-secondary py-1" v-for="file in mediaplanFiles" :key="file.id">
						<img
							class="mr-1"
							height="20"
							:src="`/images/file_icon_${file.extension.toLowerCase()}.svg`"
							:alt="file.extension.toLowerCase()"
						/>
						<a :href="file.url" title="Médiaplán">Médiaplán ({{ Math.round(file.size / 1024) }} kB)</a>
						<button
							type="button"
							v-if="isAdmin"
							class="close text-white ml-1"
							aria-label="Odstranit"
							v-on:click="removeFile(file)"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				</div>
			</div>
			<div class="form-group col-12 col-md-3 offset-md-3 d-flex align-items-end">
				<b-form-checkbox
					v-model="item.$inputs.finished"
					:id="getInputId(item, 'finished')"
					class="w-100"
					@change="item.$errors.finished = item.finished !== item.$inputs.finished ? '' : null"
					:disabled="!isAdmin"
					size="lg"
					:button-variant="item.$inputs.finished ? 'success py-1 w-100' : 'tiscali py-1 w-100'"
					block
					button
				>
					Kampaň {{ item.$inputs.finished ? "splněna" : "nesplněna" }}
				</b-form-checkbox>
			</div>
		</div>
		<div class="admin-section">
			<h6 class="mb-1">Fakturační údaje</h6>
			<hr class="mt-0 mb-2" />
			<div class="form-row">
				<div class="form-group col-12 col-md-2 col-xl-2">
					<label class="mb-1" :for="getInputId(item, 'on_behalf_alias')">Jménem:</label>
					<b-form-select
						v-model="item.$inputs.on_behalf_alias"
						:id="getInputId(item, 'on_behalf_alias')"
						:options="onBehalfOptions"
						:disabled="!canEdit"
						@change="onSelectChange(onBehalfOptions)"
						:state="item.$states.on_behalf_alias"
						class="d-block"
						size="sm"
						style="font-size: 13px"
						:required="required.indexOf('on_behalf_alias') > -1"
					>
					</b-form-select>
					<div class="invalid-tooltip truncate" v-if="item.$errors.type">{{ item.$errors.type }}</div>
				</div>
				<div class="form-group col-12 col-md-4 col-xl-2">
					<label class="mb-1" :for="getInputId(item, 'credit')">Dobropis:</label>
					<formatted-num-input
						v-model="item.$inputs.credit"
						placeholder="Zadejte částku.."
						:id="getInputId(item, 'credit')"
						size="sm"
						suffix=" Kč"
						:state="item.$states.credit"
						@input="onGenericInput(item, 'credit', false)"
						:readOnly="!canEditInvoice"
					>
					</formatted-num-input>
					<div class="invalid-tooltip truncate" v-if="item.$errors.credit">{{ item.$errors.credit }}</div>
				</div>
				<div class="form-group col-12 col-md-3 col-xl-2">
					<label class="mb-1" :for="getInputId(item, 'invoice_tax_date')">DÚZP:</label>
					<b-form-datepicker
						v-model="item.$inputs.invoice_tax_date"
						placeholder="Zadejte datum.."
						:id="getInputId(item, 'invoice_tax_date')"
						size="sm"
						locale="cs"
						:date-format-options="dateFormat"
						:state="item.$states.invoice_tax_date"
						@input="dateValidator(item, 'invoice_tax_date')"
						:disabled="!canEditInvoice"
						label-reset-button="Smazat"
						label-close-button="Zavřít"
						start-weekday="1"
						reset-button
						close-button
					>
					</b-form-datepicker>
					<div class="invalid-tooltip truncate" v-if="item.$errors.invoice_tax_date">
						{{ item.$errors.invoice_tax_date }}
					</div>
				</div>
				<div class="form-group col-12 col-md-3 col-xl-2">
					<label class="mb-1" :for="getInputId(item, 'invoice_due_date')">Splatnost:</label>
					<b-form-datepicker
						v-model="item.$inputs.invoice_due_date"
						placeholder="Zadejte datum.."
						:id="getInputId(item, 'invoice_due_date')"
						size="sm"
						locale="cz"
						:date-format-options="dateFormat"
						:state="item.$states.invoice_due_date"
						@input="dateValidator(item, 'invoice_due_date')"
						:disabled="!canEditInvoice"
						label-reset-button="Smazat"
						label-close-button="Zavřít"
						start-weekday="1"
						reset-button
						close-button
					>
					</b-form-datepicker>
					<div class="invalid-tooltip truncate" v-if="item.$errors.invoice_due_date">
						{{ item.$errors.invoice_due_date }}
					</div>
				</div>
				<div class="form-group col-12 col-md-4 col-xl-2">
					<label class="mb-1" :for="getInputId(item, 'invoice_number')">Číslo faktury:</label>
					<b-form-input
						v-model="item.$inputs.invoice_number"
						:id="getInputId(item, 'invoice_number')"
						size="sm"
						placeholder="Zadejte ID faktury"
						:state="item.$states.invoice_number"
						@input="onGenericInput(item, 'invoice_number')"
						:disabled="!canEditInvoice"
					>
					</b-form-input>
					<div class="iinvalid-tooltip truncate" v-if="item.$errors.invoice_number">
						{{ item.$errors.invoice_number }}
					</div>
				</div>
				<div class="form-group col-12 col-md-6">
					<label class="mb-1" :for="getInputId(item, 'invoiceFile')">Faktura, dobropis:</label>
					<b-form-file
						v-model="item.$inputs.$invoiceFile"
						accept=".pdf"
						class="d-block"
						:id="getInputId(item, 'invoiceFile')"
						@input="onFileInput(item, 'invoice')"
						:state="item.$states.$invoiceFile"
						placeholder="Vyberte nebo přesuňte soubor..."
						drop-placeholder="Přesuňte sem..."
						:disabled="!canEditInvoice"
					>
					</b-form-file>
					<div class="invalid-tooltip truncate" v-if="item.$errors.$invoiceFile">
						{{ item.$errors.$invoiceFile }}
					</div>
				</div>
				<div class="form-group col-12 col-md-6" v-if="invoiceFiles.length">
					<div class="mb-1">Soubory:</div>
					<div class="file-badge badge badge-secondary py-1" v-for="file in invoiceFiles" :key="file.id">
						<img
							class="mr-1"
							height="20"
							:src="`/images/file_icon_${file.extension.toLowerCase()}.svg`"
							:alt="file.extension.toLowerCase()"
						/>
						<a :href="file.url" title="Faktura/dobropis"
							>Faktura/dobropis ({{ Math.round(file.size / 1024) }} kB)</a
						>
						<button
							type="button"
							v-if="isAdmin"
							class="close text-white ml-1"
							aria-label="Odstranit"
							v-on:click="removeFile(file)"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</b-modal>
</template>

<script>
import { BFormFile, BModal } from "bootstrap-vue";
import inputMixin from "../mixins/input_mixin";

export default {
	components: {
		BFormFile,
		BModal,
	},
	mixins: [inputMixin],
	data() {
		const inputData = this.inputData();
		const required = ["invoice_month", "trader", "type", "date_from", "date_to", "client", "name", "price"];
		let isLocked = false;
		if (this.parent.trader && this.parent.invoice_month) {
			const invoiceMonth = new Date(this.parent.invoice_month);
			invoiceMonth.setDate(1);
			invoiceMonth.setHours(0, 0, 0, 0);
			isLocked = invoiceMonth < this.parent.trader.$openMonth;
		}
		if (!inputData.isAdmin) {
			required.push("format");
			required.push("placements");
		}

		const stateOptions = [
			{ value: null, text: "založena", bg: "secondary", color: "white", disabled: true },
			{ value: "deployed", text: "nasazena", bg: "warning", color: "dark", disabled: true },
			{ value: "approved", text: "schválena", bg: "tiscali", color: "white", disabled: true },
			{ value: "finished", text: "splněna", bg: "success", color: "white", disabled: true },
		];

		const canEdit = inputData.isAdmin || (!isLocked && inputData.isTrader && this.parent.type !== "order");
		const canEditInvoice = inputData.isAdmin && this.parent?.finished;

		const typeOptions = [
			{ value: "rezervation", text: "Rezervace" },
			{ value: "order", text: "Objednávka" },
		];

		const onBehalfOptions = import.meta.env.MODE === "pmx" && [{ value: "PX", text: "PX" }] || [
			{ value: "TM", text: "TM" },
			{ value: "ARBO", text: "ARBO" },
		];
		if (!inputData.isAccountant) {
			typeOptions.push({ value: "proforma", text: "Proforma", disabled: !inputData.isSuperAdmin });
		}

		return Object.assign(inputData, {
			moveTo: null,
			canCreate: inputData.isTrader,
			canEdit,
			canEditInvoice,
			canSubmit: canEdit || canEditInvoice,
			isLocked,
			typeOptions,
			stateOptions,
			onBehalfOptions,
			$uidType: "",
			dateFormat: { year: "numeric", month: "numeric", day: "numeric" },
			monthFormat: { year: "numeric", month: "numeric", day: undefined },
			required,
			clientNames: this.parent.client ? [this.parent.client.name] : [],
			agencyNames: this.parent.agency ? [this.parent.agency.name] : [],
			item: this.setItemContext(Object.assign({}, this.parent), {
				agency: null,
				client: null,
				trader: null,
				format: null,
				placements: null,
				price: null,
				invoice_month: null,
				name: null,
				files: null,
				description: null,
				finished: false,
				type: null,
				date_from: null,
				date_to: null,
				bonus: null,
				$mediaplanFile: null,
				credit: null,
				invoice_tax_date: null,
				invoice_due_date: null,
				invoice_number: null,
				on_behalf_alias: onBehalfOptions[0].value,
				$invoiceFile: null,
			}),
		});
	},
	props: {
		parent: {
			type: Object,
			default: () => {
				return {};
			},
		},
		visible: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		invoiceFiles() {
			return this.item.files ? this.item.files.filter((f) => f.type === "invoice") : [];
		},
		mediaplanFiles() {
			return this.item.files ? this.item.files.filter((f) => f.type === "mediaplan") : [];
		},
	},
	methods: {
		deleteItem(modelType, item, callbackFc) {
			if (!window.confirm(`Opravdu smazat kampaň včetně přiložených souborů?`)) {
				return;
			}
			(async () => {
				return this.fetchApi(`/${modelType}/${item.id}`, {
					method: "DELETE",
					headers: {
						"Content-Type": "application/json",
					},
				}).then((_) => {
					this.$emit("delete", item);
					callbackFc && callbackFc();
				});
			})().catch((err) => console.error(err.message || err.statusText || err));
		},
		async onSubmit(item) {
			if (this.itemChangedAndValid(item, true) !== 1 || !window.confirm("Opravdu uložit?")) {
				return;
			}

			const createOp = !item.id;

			const saved = await this.submit("campaign", item, true);
			if (!saved && createOp) {
				return;
			}
			for (const fileType of ["invoice", "mediaplan"]) {
				const inputName = `$${fileType}File`;
				if (item.$inputs[inputName]) {
					const formData = new FormData();
					formData.append("campaign_id", item.id);
					formData.append("type", fileType);
					formData.append("attachment", item.$inputs[inputName]);
					const file = await this.fetchApi("/file", {
						method: "POST",
						body: formData,
					}).catch((err) => {
						err = err.message || err.statusText || err;
						item.$states[inputName] = false;
						item.$errors[inputName] = err;
					});
					if (!file) {
						break;
					}
					item.files = item.files || [];
					item.files.push(file);
					item.$states[inputName] = null;
					item.$errors[inputName] = null;
				}
			}
			this.$forceUpdate();
			this.$emit(createOp ? "create" : "update", item);
		},
		onSelectChange(key, options) {
			if (this.item.$inputs[key] && !options.some((opt) => opt.value === this.item.$inputs[key])) {
				this.item.$errors[key] = "Nevalidní hodnota.";
				this.item.$states[key] = false;
				return;
			}
			this.onSelectInput(this.item, key, true);
		},
		searchAdapter(modelType) {
			const route = modelType === "placements" ? "placement" : modelType;
			return (searchValue) => {
				return this.fetchApi(`/${route}?search=${encodeURI(searchValue)}`)
					.then((data) => {
						if (!data || !data.count) {
							this.item.$errors[modelType] = "Žádné výsledky";
							return [];
						}
						this.item.$errors[modelType] = "";
						return data.rows;
					})
					.catch((err) => {
						err = err.message || err.statusText || err;
						this.item.$errors[modelType] = err;
						return [];
					});
			};
		},
		removeFile(file, noConfirm) {
			if (!this.isAdmin || (!noConfirm && !window.confirm(`Opravdu odstranit přílohu?`))) {
				return;
			}
			return this.fetchApi(`/file/${file.id}`, {
				method: "DELETE",
			})
				.then((_) => {
					this.item.files = this.item.files.filter((f) => f.id !== file.id);
					this.item.$states[`$${file.type}File`] = null;
					this.item.$errors[`$${file.type}File`] = null;
					this.$emit("removeFile", this.item);
				})
				.catch((err) => {
					err = err.message || err.statusText || err;
					this.item.$errors[`$${file.type}File`] = err;
				});
		},
		getCompanyAddTag(modelType) {
			const chainRequest = (req) => {
				return req.then((data) => {
					if (Array.isArray(data)) {
						if (data.length) {
							this.onAddOneToManyRelation(modelType, this.item, data[0]);
							return data[0].name;
						}
					} else if (data && data.rows) {
						if (data.rows.length) {
							this.onAddOneToManyRelation(modelType, this.item, data.rows[0]);
							return data.rows[0].name;
						}
					} else if (data && data.id) {
						this.onAddOneToManyRelation(modelType, this.item, data);
						return data.name;
					}
					return "";
				});
			};

			return async (uidStr) => {
				if (!this.$uidType || !uidStr) {
					return "";
				}
				let name = await chainRequest(this.fetchApi(`/company/${this.$uidType}/${uidStr}`, null, false))
					.then((name) => {
						return !name && this.$uidType === "ico"
							? chainRequest(this.fetchApi(`/ares?ico=${uidStr}`, null, false))
							: name;
					})
					.catch((err) => (this.item.$errors[modelType] = err.message || err.statusText || err));
				if (this.item.$errors[modelType]) {
					this.item.$states[modelType] = false;
					return "";
				} else if (!name) {
					this.item.$errors[modelType] = `${
						this.$uidType === "ico" ? "IČO" : "DIČ"
					} nenalezeno. Zkontrolujte správnost.`;
					this.item.$states[modelType] = false;
					return "";
				}
				this.item.$states[modelType] = true;
				return name;
			};
		},
		onCompanyInput(val, modelType, required) {
			if (val && val.length) {
				// pokud je hodnota, prochazi validaci a ajaxem
				return;
			}

			this.onRemoveOneToManyRelation(modelType, this.item, null, required);
		},
		onFileInput(item, key, required) {
			const inputName = `$${key}File`;
			if (required && !(item.$inputs[inputName] || item.files.some((f) => f.type === key))) {
				item.$errors[inputName] = "Toto pole je povinné.";
				item.$states[inputName] = false;
				return;
			}
			item.$errors[inputName] = item.$inputs[inputName] ? "" : null;
			item.$states[inputName] = item.$inputs[inputName] ? true : null;
		},
		getCompanyTagValidator(modelType, required) {
			// kontrola validity ICO
			return (tag) => {
				if (tag.length < 2 && required) {
					this.item.$errors[modelType] = "Toto pole je povinné.";
					this.item.$states[modelType] = false;
					return false;
				}

				this.$uidType = "ico";
				let err = this.validateICO(this.item, tag);
				if (err) {
					this.$uidType = "dic";
					err = this.validateDIC(this.item, tag);
					if (err) {
						this.$uidType = "";
					}
				}

				this.item.$errors[modelType] = err;
				switch (err) {
					case null:
						this.item.$states[modelType] = null;
						break;
					case "":
						this.item.$states[modelType] = true;
						break;
					default:
						this.item.$states[modelType] = false;
				}
				return this.item.$states[modelType];
			};
		},
	},
};
</script>
