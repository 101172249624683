<template>
	<div class="container-fluid">
		<div class="standalone-header" v-if="!parent">
			<h2 class="mb-3">
				Kampaně
				<button
					type="button"
					class="btn p-0 mb-1 align-middle d-print-none"
					v-if="!modalItem && isTrader"
					@click="
						modalItem = {};
						modalVisible = true;
					"
				>
					<svg
						version="1.1"
						xmlns="http://www.w3.org/2000/svg"
						xmlns:xlink="http://www.w3.org/1999/xlink"
						width="20px"
						height="20px"
						x="0px"
						y="0px"
						viewBox="0 0 42 42"
						xml:space="preserve"
					>
						<path
							fill="#62cb35"
							d="M37.059,16H26V4.941C26,2.224,23.718,0,21,0s-5,2.224-5,4.941V16H4.941C2.224,16,0,18.282,0,21s2.224,5,4.941,5H16v11.059 C16,39.776,18.282,42,21,42s5-2.224,5-4.941V26h11.059C39.776,26,42,23.718,42,21S39.776,16,37.059,16z"
						/>
					</svg>
				</button>
			</h2>
		</div>
		<h5 class="mb-2" v-else>Kampaně</h5>
		<!-- Main table element -->
		<div class="row mb-2" v-if="!parent">
			<div class="form-group col-12" v-bind:class="$root.print && !tags.length ? 'd-print-none' : ''">
				<b-form-tag-typeahead-groups
					v-model="tags"
					:format-value="formatSearch"
					:id="getInputId('search')"
					:search-provider="searchTags"
					:limit="20"
					size="sm"
					placeholder="Zadejte tagy.."
					@remove="changeSearch($event, true)"
					@add="changeSearch($event)"
				>
				</b-form-tag-typeahead-groups>
			</div>
			<div class="form-group form-inline col-6 col-sm-auto">
				<label class="smaller mr-2" :for="getInputId('status')">Stav:</label>
				<b-form-select v-model="statusFilter" :id="getInputId('status')" @change="onFilteredRefresh" size="sm">
					<b-form-select-option
						v-for="option in statusOptions"
						:value="option.value"
						:key="option.value"
						:selected="option.value === statusFilter"
					>
						{{ option.text }}
					</b-form-select-option>
				</b-form-select>
			</div>
			<div class="form-group form-inline col-6 col-sm-auto">
				<label class="smaller mr-2" :for="getInputId('type')">Typ:</label>
				<b-form-select v-model="typeFilter" :id="getInputId('type')" @change="onFilteredRefresh" size="sm">
					<b-form-select-option
						v-for="option in typeOptions"
						:value="option.value"
						:key="option.value"
						:selected="option.value === typeFilter"
					>
						{{ option.text }}
					</b-form-select-option>
				</b-form-select>
			</div>
			<div class="form-group form-inline col-6 col-sm-auto">
				<label class="smaller mr-2" :for="getInputId('on_behalf_alias')">Jménem:</label>
				<b-form-select v-model="on_behalf_aliasFilter" :id="getInputId('on_behalf_alias')" @change="onFilteredRefresh" size="sm">
					<b-form-select-option
						v-for="option in onBehalfOptions"
						:value="option.value"
						:key="option.value"
						:selected="option.value === on_behalf_aliasFilter"
					>
						{{ option.text }}
					</b-form-select-option>
				</b-form-select>
			</div>	
			<div class="form-group form-inline col-6 col-sm-auto">
				<label class="smaller mr-2" :for="getInputId('invoice_month_from')">Od:</label>
				<b-form-datepicker
					v-model="invoice_month_fromFilter"
					type="date"
					placeholder="Fakturace od.."
					:id="getInputId('invoice_month')"
					size="sm"
					style="width: 150px"
					:max="invoice_month_toFilter"
					:date-format-options="dateFormat"
					@input="onFilteredRefresh"
					locale="cs"
					label-reset-button="Smazat"
					label-close-button="Zavřít"
					start-weekday="1"
					reset-button
					close-button
				>
				</b-form-datepicker>
			</div>
			<div class="form-group form-inline col-6 col-sm-auto">
				<label class="smaller mr-2" :for="getInputId('invoice_month_to')">Do:</label>
				<b-form-datepicker
					v-model="invoice_month_toFilter"
					type="date"
					placeholder="Fakturace do.."
					:id="getInputId('invoice_month_to')"
					style="width: 150px"
					size="sm"
					:min="invoice_month_fromFilter"
					:date-format-options="dateFormat"
					@input="onFilteredRefresh"
					locale="cs"
					label-reset-button="Smazat"
					label-close-button="Zavřít"
					start-weekday="1"
					reset-button
					close-button
				>
				</b-form-datepicker>
			</div>
		</div>
		<b-table
			ref="table"
			show-empty
			stacked="md"
			:items="rowProvider"
			:fields="fields"
			:current-page="currentPage"
			:per-page="perPage"
			:filter="filter"
			:sort-by.sync="sortBy"
			:sort-desc.sync="sortDesc"
			class="campaign-table"
			:thead-class="'text-nowrap'"
			:tbody-class="'text-nowrap'"
			:tbody-tr-class="rowClass"
			:empty-text="'Žádné kampaně k zobrazení.'"
			:empty-filtered-text="'Žádná kampaň nevyhovuje vybraným kritériím.'"
			@row-clicked="toggleRowDetails"
			@filtered="onFiltered"
			no-sort-reset
		>
			<template v-slot:cell(agency)="{ value }">
				<span
					v-if="(value && value.valid) || !isAdmin"
					class="line-clamp-2 overflow-hidden"
					v-bind:class="!value || !value.valid ? 'invalid-uline' : ''"
					>{{ value.name }}</span
				>
				<router-link
					v-else-if="value"
					:to="{ name: 'company', query: { id: value.id } }"
					class="invalid-uline line-clamp-2 overflow-hidden"
					title="Nevalidní subjekt. Opravte."
				>
					{{ value.name }}
				</router-link>
			</template>

			<template v-slot:cell(trader)="{ value }">
				{{ value.alias ? value.alias : "" }}
				<span
					v-if="!value.$lastMonthClosed"
					class="closed-month-check form-control is-invalid"
					title="Předchozí měsíc neuzavřen"
				>
				</span>
			</template>

			<template v-slot:cell(client)="{ value }">
				<span
					v-if="(value && value.valid) || !isAdmin"
					class="line-clamp-2 overflow-hidden"
					v-bind:class="!value || !value.valid ? 'invalid-uline' : ''"
					>{{ value.name }}</span
				>
				<router-link
					v-else-if="value"
					:to="{ name: 'company', query: { id: value.id } }"
					class="invalid-uline line-clamp-2 overflow-hidden"
					title="Nevalidní subjekt. Opravte."
				>
					{{ value.name }}
				</router-link>
			</template>

			<template v-slot:cell(name)="{ item }">
				<div class="line-clamp-2 overflow-hidden">
					<div
						class="badge badge-sm text-white"
						v-bind:class="typeTagMap[item.type].cls"
					>
						{{ typeTagMap[item.type].label }}
					</div>
					{{ item.name }}
				</div>
			</template>

			<template v-slot:table-busy>
				<div class="text-center text-secondary my-2">
					<b-spinner class="align-middle"></b-spinner>
					<strong>Načítám...</strong>
				</div>
			</template>

			<template v-slot:custom-foot>
				<b-tr v-if="price_sum !== null">
					<b-td colspan="5">
						<div v-if="lackingTraders && lackingTraders.length">
							<span class="smaller text-danger">Neuzavřeno:</span>
							<div class="badge badge-tiscali mr-1" v-for="t in lackingTraders" :key="t.alias">
								{{ t.alias }}
							</div>
						</div>
					</b-td>
					<b-td class="text-right text-tiscali">
						<strong>Celkem</strong>
					</b-td>
					<b-td class="text-right text-tiscali text-nowrap">
						<strong>{{ price_sum ? getFormattedNumber(price_sum) + " Kč" : "0 Kč" }}</strong>
					</b-td>
					<b-td class="text-right text-tiscali text-nowrap">
						<strong>{{ plan_price_sum ? getFormattedNumber(plan_price_sum) + " Kč" : "0 Kč" }}</strong>
					</b-td>
				</b-tr>
			</template>
		</b-table>

		<div class="row d-print-none" v-if="!parent && totalRows">
			<div class="col">
				<b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" />
			</div>

			<div class="col-auto d-print-none p-0" v-if="price_sum !== null">
				<b-dropdown text="Stáhnout" size="sm">
					<b-dropdown-item @click.prevent="exportAsExcelFile()">xlsx</b-dropdown-item>
					<b-dropdown-item @click.prevent="handlePdfClick()">pdf</b-dropdown-item>
				</b-dropdown>
			</div>

			<div class="col-auto">
				<b-form-select
					v-model="perPage"
					:id="getInputId('perPaget')"
					:options="[
						{ value: 10, text: '10 položek' },
						{ value: 20, text: '20 položek' },
						{ value: 50, text: '50 položek' },
					]"
					@change="refreshTable"
					size="sm"
				>
				</b-form-select>
			</div>
		</div>

		<campaign-modal-form
			v-if="!parent && modalItem"
			:parent="modalItem"
			:visible="modalVisible"
			@hide="hideModal($event)"
			@hidden="modalItem = null"
			@removeFile="onRowChanged($event, false, true)"
			@update="onRowChanged($event)"
			@delete="onRowChanged($event, true)"
			@create="onRowChanged($event, true)"
		>
		</campaign-modal-form>

		<div class="btn-right-bottom-modal p-2 pb-0 d-print-none" v-if="traderCloseMonth.length">
			<button
				class="btn btn-tiscali btn-block mb-2"
				v-for="trader in traderCloseMonth"
				@click="closeTraderMonth(trader)"
				:key="trader.alias"
			>
				{{ trader.alias }}: uzavřít {{ trader.openMonth.getMonth() + 1 }}/{{ trader.openMonth.getFullYear() }}
			</button>
		</div>
		<div style="position: absolute; z-index: 1040" v-if="printing">
			<div class="modal fade show d-block">
				<div
					class="modal-dialog modal-sm modal-dialog-centered container-fluid d-flex justify-content-center text-center text-white"
				>
					<b-spinner class="align-middle mr-2"></b-spinner>
					<strong>Zpracovávám...</strong>
				</div>
			</div>
			<div class="modal-backdrop"></div>
		</div>
	</div>
</template>

<script>
import inputMixin from "../mixins/input_mixin";
import tableMixin from "../mixins/table_mixin";
import CampaignModalForm from "../forms/campaign_mform.vue";
import BFormTagTypeaheadGroups from "../util/tags-typeahead-groups.vue";
import { utils, writeFileXLSX } from "xlsx";
const currentDate = new Date();
currentDate.setHours(12, 0, 0, 0);

const defaultParams = {
	offset: 0,
	limit: 20,
	sort: "id",
	desc: 1,
};

const typeTagMap = {
	order: {
		label: "OBJ",
		cls: "badge-success"
	},
	rezervation: {
		label: "REZ",
		cls: "badge-tiscali"
	},
	proforma: {
		label: "PRO",
		cls: "badge-secondary"
	},
}

const filter = {
	id: {
		type: Number,
	},
	status: {
		type: String,
		default: "",
	},
	type: {
		type: String,
		default: "",
	},
	on_behalf_alias: {
		type: String,
		default: "",
	},
	invoice_month_from: {
		type: String,
	},
	invoice_month_to: {
		type: String,
	},
	company_id: {
		type: [Number, Array],
	},
	trader_id: {
		type: [Number, Array],
	},
	format_id: {
		type: [Number, Array],
	},
	placement_id: {
		type: [Number, Array],
	},
};

const statusOptions = [
	{ value: "", text: "Vše" },
	{ value: "tofinish", text: "Nesplněna" },
	{ value: "topay", text: "Splněna/k fakturaci" },
	{ value: "invoiced", text: "Vyfakturována" },
];

export default {
	props: Object.assign(tableMixin.methods.getDefaultProps(defaultParams), filter),
	mixins: [tableMixin, inputMixin],
	components: {
		CampaignModalForm,
		BFormTagTypeaheadGroups,
	},
	data() {
		this.getSearchTags();
		const inputData = this.inputData();
		const typeOptions = [
			{ value: "", text: "Vše" },
			{ value: "rezervation", text: "Rezervace" },
			{ value: "order", text: "Objednávka" },
		];
		const onBehalfOptions = [
			{ value: "", text: "Vše" },
			...(
				import.meta.env.MODE === "pmx" && [{ value: "PX", text: "PX" }] 
				|| [
					{ value: "TM", text: "TM" },
					{ value: "ARBO", text: "ARBO" },
				]
			)
		];
		if (!inputData.isAccountant) {
			typeOptions.push({ value: "proforma", text: "Proforma" });
		}
		return Object.assign(this.tableData(filter), inputData, {
			fields: [
				{ key: "id", label: "ID", sortable: !this.parent },
				{ key: "trader", label: "Acc", sortable: !this.parent, tdClass: "position-relative" },
				{ key: "agency", label: "Agentura", sortable: !this.parent, class: "text-wrap" },
				{ key: "client", label: "Klient", sortable: !this.parent, class: "text-wrap" },
				{ key: "name", label: "Název", sortable: !this.parent, class: "text-wrap" },
				{
					key: "invoice_month",
					label: "Období",
					sortable: !this.parent,
					formatter: (value, key, item) => {
						return value ? value.split("-").slice(0, 2).reverse().join("/") : "";
					},
				},
				{
					key: "price",
					label: "Cena NN",
					sortable: !this.parent,
					class: "font-weight-bold text-nowrap table-num-col",
					formatter: (value, key, item) => {
						return value ? this.getFormattedNumber(value) + " Kč" : "";
					},
				},
				{
					key: "$plan_price",
					label: "Cena NNN",
					class: "font-weight-bold text-nowrap table-num-col last",
					formatter: (value, key, item) => {
						return value ? this.getFormattedNumber(value) + " Kč" : "";
					},
				},
			],
			tags: [],
			apiUri: "campaign",
			price_sum: 0,
			plan_price_sum: 0,
			statusOptions,
			typeOptions,
			onBehalfOptions,
			dateFormat: { year: "numeric", month: "numeric", day: "numeric" },
			traderCloseMonth: [],
			closingMonth: false,
			lackingTraders: [],
			defaultParams,
			typeTagMap
		});
	},
	created() {
		this.setTraderCloseMonth();
	},
	methods: {
		rowClass(item, type) {
			if (!item) return;

			return `campaign-row status-${
				(item.invoice_number && "invoiced") || (item.finished && "finished") || "null"
			}`;
		},
		onProviderData(data) {
			this.price_sum = "price_sum" in data ? this.roundTwoDecimals(data.price_sum || 0) : null;
			this.plan_price_sum = "plan_price_sum" in data ? this.roundTwoDecimals(data.plan_price_sum || 0) : null;
			if (data.lackingTraders) {
				this.lackingTraders = data.lackingTraders;
			}
			const firstDayCurrentMonthDate = new Date();
			firstDayCurrentMonthDate.setDate(1);
			firstDayCurrentMonthDate.setHours(0, 0, 0, 0);
			data.rows.forEach((item) => {
				if (item.trader) {
					item.trader.$openMonth = new Date(item.trader.open_month);
					item.trader.$openMonth.setDate(1);
					item.trader.$openMonth.setHours(0, 0, 0, 0);
					item.trader.$lastMonthClosed = item.trader.$openMonth >= firstDayCurrentMonthDate;
				}
			});
		},
		async exportAsExcelFile() {
			const maxCnt = 2000;
			if (this.totalRows > maxCnt) {
				this.$root.msg = {
					value: "Překročen limit položek pro export.",
					type: "alert-danger",
				};
				return;
			}

			try {
				let rows = this.items;
				if (this.totalRows > this.perPage) {
					this.printing = true;
					let queryParams = new URLSearchParams(this.queryFilter).toString().replace(/&?limit=\d+/, "");
					queryParams = `?${queryParams}${queryParams ? "&" : ""}limit=${maxCnt}`;
					rows = await this.fetchApi(`/${this.apiUri}${queryParams}`).then((data) => data.rows)
				}
				const ws = utils.json_to_sheet(
					rows.map((p) => ({
						id: p.id,
						Acc: p.trader?.alias || "",
						Agentura: p.agency?.name || "",
						Klient: p.client?.name || "",
						Název: p.name || "",
						Období: p.invoice_month || "",
						Od: p.date_from || "",
						Do: p.date_to || "",
						"Cena NN": p.price,
						"Cena NNN": this.getPlanPrice(p),
						Jménem: p.on_behalf_alias || "",
						"Číslo faktury": p.invoice_number || "",
						DUZP: p.invoice_tax_date || "",
						Splatnost: p.invoice_due_date || "",
						Poznámka: p.description || "",
					}))
				);
				const wb = utils.book_new();
				utils.book_append_sheet(wb, ws, "Kampane");
				writeFileXLSX(wb, `buying_${this.$route.name}_${window.location.search}.xlsx`);
			} catch(err) {
				console.error(err);
			}
			this.printing = false;
		},
		setItemContext(item) {
			if (item.price) {
				item.$plan_price = this.getPlanPrice(item);
			}
			return;
		},
		getPlanPrice(item) {
			if (!item.price) {
				return 0;
			}

			if (item.bonus || item.credit) {
				return this.roundTwoDecimals(item.price * (1 - (item.bonus || 0) / 100) - (item.credit || 0));
			} else {
				return item.price;
			}
		},
		formatSearch(obj) {
			switch (obj.$type) {
				case "trader":
					return `${(this.$root.print && obj.name) || obj.alias} (obchodník)`;
				case "company":
					return `${obj.name} (firma)`;
				case "format":
				case "placement":
					return `${obj.description} (${obj.$type === "format" ? "formát" : "umístění"})`;
			}
		},
		updateRow(item, newData) {
			Object.assign(item, newData);
			this.setItemContext(item);
		},
		updateArrayParams(key, value, remove) {
			if (!value) {
				return;
			}
			const valStr = value.toString();
			const filterKey = key + "Filter";
			if (remove) {
				if (Array.isArray(this[filterKey])) {
					this[filterKey] = this[filterKey].filter((v) => valStr !== v);
					if (!this[filterKey].length) {
						this[filterKey] = undefined;
					}
				} else {
					this[filterKey] = undefined;
				}
			} else {
				if (Array.isArray(this[filterKey])) {
					!this[filterKey].includes(valStr) && this[filterKey].push(valStr);
				} else if (this[filterKey]) {
					this[filterKey] = [this[filterKey], valStr];
				} else {
					this[filterKey] = valStr;
				}
			}
			this.onFilteredRefresh();
		},
		async getSearchTags() {
			const tags = [];
			if (this.trader_id) {
				const traderIds = this.getQueryArrayIds(this.trader_id);

				if (traderIds.length) {
					const queryParams = new URLSearchParams({ id: traderIds }).toString();
					const traders = await this.chainListRequest(
						this.fetchApi(`/trader?${queryParams}`, null, false)
					).catch((err) => {
						console.error(err.message || err.statusText || err);
					});

					traders.forEach((t) => (t.$type = "trader"));

					traders && traders.length && tags.push(...traders);
				}
			}

			if (this.company_id) {
				const companyIds = this.getQueryArrayIds(this.company_id);

				if (companyIds.length) {
					const queryParams = new URLSearchParams({ id: companyIds }).toString();
					const companies = await this.chainListRequest(
						this.fetchApi(`/company?${queryParams}`, null, false)
					).catch((err) => {
						console.error(err.message || err.statusText || err);
					});

					if (companies && companies.length) {
						companies.forEach((c) => (c.$type = "company"));
						tags.push(...companies);
					}
				}
			}

			if (this.format_id) {
				const formatIds = this.getQueryArrayIds(this.format_id);

				if (formatIds.length) {
					const queryParams = new URLSearchParams({ id: formatIds }).toString();
					const formats = await this.chainListRequest(
						this.fetchApi(`/format?${queryParams}`, null, false)
					).catch((err) => {
						console.error(err.message || err.statusText || err);
					});

					formats.forEach((f) => (f.$type = "format"));

					formats && formats.length && tags.push(...formats);
				}
			}

			if (this.placement_id) {
				const placementIds = this.getQueryArrayIds(this.placement_id);

				if (placementIds.length) {
					const queryParams = new URLSearchParams({ id: placementIds }).toString();
					const placements = await this.chainListRequest(
						this.fetchApi(`/placement?${queryParams}`, null, false)
					).catch((err) => {
						console.error(err.message || err.statusText || err);
					});

					placements.forEach((p) => (p.$type = "placement"));

					placements && placements.length && tags.push(...placements);
				}
			}

			if (tags.length) {
				this.tags.push(...tags);
			}
		},
		getQueryArrayIds(value) {
			return (Array.isArray(value) ? value : [value]).map((id) => Number(id)).filter((id) => this.isNumber(id));
		},
		changeSearch(tag, remove) {
			if (!tag.$type) {
				return;
			}

			if (remove) {
				this.tags = this.tags.filter((t) => t.id !== tag.id || t.$type !== tag.$type);
			} else if (!this.tags.some((t) => t.id === tag.id && t.$type === tag.$type)) {
				this.tags.push(tag);
			} else {
				return;
			}
			this.updateArrayParams(`${tag.$type}_id`, tag.id, remove);
		},
		chainListRequest(req) {
			return req.then((data) => {
				if (Array.isArray(data)) {
					if (data.length) {
						return data;
					}
				} else if (data && data.rows) {
					if (data.rows.length) {
						return data.rows;
					}
				}
				return [];
			});
		},
		async setTraderCloseMonth() {
			const result = await this.chainListRequest(this.fetchApi(`/trader?own=1`, null, false));
			if (!result) {
				return;
			}
			const firstDayCurrentMonthDate = new Date();
			firstDayCurrentMonthDate.setDate(1);
			firstDayCurrentMonthDate.setHours(0, 0, 0, 0);
			for (const trader of result) {
				trader.openMonth = new Date(trader.open_month);
				if (trader.openMonth < firstDayCurrentMonthDate) {
					this.traderCloseMonth.push(trader);
				}
			}
		},
		async closeTraderMonth(trader) {
			if (!window.confirm(`Opravdu uzavřít měsíc?`)) {
				return;
			}
			this.closingMonth = true;
			await this.fetchApi(`/trader/${trader.id}/close-month`, {
				method: "PATCH",
			})
				.then((result) => {
					this.traderCloseMonth = this.traderCloseMonth.filter((t) => t.id !== trader.id);
				})
				.catch((err) => {
					console.error(err);
				});
			this.closingMonth = false;
		},
		async searchTags(name) {
			const searchArr = [
				{ title: "Firmy", model: "company" },
				{ title: "Obchodníci", model: "trader" },
				{ title: "Formát", model: "format" },
				{ title: "Umístění", model: "placement" },
			];
			const results = [];
			try {
				for (const def of searchArr) {
					const result = await this.chainListRequest(
						this.fetchApi(`/${def.model}?search=${encodeURI(name)}`, null, false)
					);
					if (result && result.length) {
						result.forEach((item) => (item.$type = def.model));
						results.push({
							title: def.title,
							options: result,
						});
					}
				}
				this.loading = false;
				return results;
			} catch (err) {
				this.loading = false;
				return [];
			}
		},
		roundTwoDecimals(num) {
			return Math.round((num + Number.EPSILON) * 100) / 100;
		},
	},
};
</script>
