<template>
	<b-modal id="week-plan-modal"
		size="xl"
		:visible="visible"
		@hide="$emit('hide', $event)"
		@hidden="$emit('hidden')"
		dialog-class="container-fluid"
		hide-footer
		centered>
		<template v-slot:modal-header="{ close }">
			<h5 class="mb-3">W{{ week.number }} 
				<small>({{ week.label }})</small>
				<button type="button" class="btn p-0 mb-1 align-middle" v-if="canCreate && !modalItem && week.campaigns.length" @click="modalItem = {week: week.number};modalVisible=true">
					<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16px" height="16px" x="0px" y="0px" viewBox="0 0 42 42" xml:space="preserve"><path fill="#62cb35" d="M37.059,16H26V4.941C26,2.224,23.718,0,21,0s-5,2.224-5,4.941V16H4.941C2.224,16,0,18.282,0,21s2.224,5,4.941,5H16v11.059 C16,39.776,18.282,42,21,42s5-2.224,5-4.941V26h11.059C39.776,26,42,23.718,42,21S39.776,16,37.059,16z"/></svg>
				</button>
			</h5>
			<button type="button" aria-label="Zavřít" class="close" @click="close()">×</button>
		</template>
		<div>
			<b-table ref="table"
				show-empty
				small
				stacked="md"
				:items="items"
				:fields="fields"
				:sort-by.sync="sortBy"
				:sort-desc.sync="sortDesc"
				:tbody-class="'text-nowrap'"
				:tbody-tr-class="rowClass"
				:empty-text="'Žádné plány k zobrazení.'"
				no-provider-paging
				no-provider-sorting
				no-sort-reset
			>
				<template v-slot:cell($campaign)="{ item }">
				<!--
					<b-form-select v-model="item.$inputs.placements_campaigns_id"
						:id="getInputId(item, 'placements_campaigns_id')"
						:options="campaignOptions"
						@change="onCampaignChange(item)"
						:state="item.$states.placements_campaigns_id"
						class="d-block"
						size="sm"
						style="font-size: 13px;"
						:required="required.indexOf('placements_campaigns_id') > -1">
						<template v-slot:first>
							<b-form-select-option :value="item.placements_campaigns_id">{{ item.$campaign.$label }}</b-form-select-option>
						</template>				
					</b-form-select>
					<div class="invalid-tooltip truncate" v-if="item.$errors.placements_campaigns_id">{{ item.$errors.placements_campaigns_id }}</div>
				-->
					<router-link :to="{name:'campaign', query: {id: item.$campaign.id}}" target='_blank'>
						<div class="line-clamp-1 overflow-hidden">
							<div class="badge badge-sm" v-bind:class="item.$campaign.type === 'order' ? 'badge-success' : 'badge-tiscali'">
								{{ item.$campaign.type === 'order' ? 'OBJ' : 'REZ' }}
							</div> {{ item.$campaign.$label }}
						</div>
					</router-link>
				</template>
				<template v-slot:cell(date_from)="{ item }">
					<b-form-datepicker v-model="item.$inputs.date_from"
						type="date"
						placeholder="Datum od.."
						:id="getInputId(item, 'date_from')"
						size="sm"
						locale="cs"
						:date-format-options="dateFormat"
						style="width:150px;"
						:min="item.$minDateFrom"
						:max="item.$maxDateFrom"
						:required="required.indexOf('date_from') > -1"
						@input="dateFromToValidator(item, 'date_from')"
						:state="item.$states.date_from"
						label-reset-button="Smazat"
						label-close-button="Zavřít"
						:disabled="!canCreate || !item.$inputs.placements_campaigns_id"
						start-weekday="1"
						reset-button
						close-button>
					</b-form-datepicker>
					<div class="invalid-tooltip truncate" v-if="item.$errors.date_from">{{ item.$errors.date_from }}</div>
				</template>
				<template v-slot:cell(date_to)="{ item }">
					<b-form-datepicker v-model="item.$inputs.date_to"
						type="date"
						placeholder="Datum do.."
						:id="getInputId(item, 'date_to')"
						size="sm"
						locale="cs"
						:min="item.$minDateTo"
						:max="item.$maxDateTo"
						:required="required.indexOf('date_to') > -1"
						:date-format-options="dateFormat"
						@input="dateFromToValidator(item, 'date_to')"
						:state="item.$states.date_to"
						style="width:150px;"
						label-reset-button="Smazat"
						label-close-button="Zavřít"
						:disabled="!canCreate || !item.$inputs.placements_campaigns_id"
						start-weekday="1"
						reset-button
						close-button>
					</b-form-datepicker>
					<div class="invalid-tooltip truncate" v-if="item.$errors.date_to">{{ item.$errors.date_to }}</div>				
				</template>
				<template v-slot:cell(impression)="{ item }">
					<formatted-num-input v-model="item.$inputs.impression"
						placeholder="Zadejte číslo.."
						size="sm"
						:int="true"
						style="width:125px"
						:id="getInputId(item, 'impression')"
						:state="item.$states.impression"
						:readOnly="!canCreate || !item.$inputs.placements_campaigns_id"
						@input="onGenericInput(item, 'impression', true)"
						:required="required.indexOf('impression') > -1">
					</formatted-num-input>
					<div class="invalid-tooltip truncate" v-if="item.$errors.impression">{{ item.$errors.impression }}</div>
				</template>
				<template v-slot:cell($btns)="{item}">
					<table-buttons v-if="canCreate"
						:item="item"
						:api-uri="apiUri"
						:on-item-state="itemChangedAndValid"
						:loading="item.$loading"
						:reset-item="resetItemInputs"
						:remove-item="deleteItem"
						:submit-item="submitItem"
						:remove-enabled="isSuperAdmin">
					</table-buttons>
				</template>
				<template v-slot:table-busy>
					<div class="text-center text-secondary my-2">
						<b-spinner class="align-middle"></b-spinner>
						<strong>Načítám...</strong>
					</div>
				</template>
			</b-table>

			<placement-week-plan-modal-form
				v-if="canCreate && modalItem"
				:plan="modalItem"
				:week="week"
				:placement="placement"
				:visible="modalVisible"
				@hide="hideModal($event)"
				@hidden="modalItem=null"
				@create="$emit('create', $event)">
			</placement-week-plan-modal-form>
		</div>
	</b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import PlacementWeekPlanModalForm from '../forms/placement_plan_mform.vue';
import weekPlanMixin from '../mixins/week_plan_mixin';
import inputMixin from '../mixins/input_mixin';
import tableMixin from '../mixins/table_mixin';

const defaultParams = {
	sort: 'week',
	desc: 0
}

export default {
	components: {
		BModal,
		PlacementWeekPlanModalForm
	},
	mixins: [tableMixin, inputMixin, weekPlanMixin],
	props: {
		visible: {
			type: Boolean,
			default: false
		},
		sort: {
			type: String,
			default: defaultParams.sort
		},
		desc: {
			type: Number,
			default: defaultParams.desc
		}
	},
	data () {
		const inputData = this.inputData();
		return Object.assign(this.tableData({}), inputData, this.planData(), {
			fields: [
				{ key: '$campaign', label: 'Kampaň', tdClass: 'align-middle'},
				{ key: 'date_from', label: 'Od', thStyle: 'width: 160px'},
				{ key: 'date_to', label: 'Do', thStyle: 'width: 160px'},
				{ key: 'impression', label: 'Imprese', sortable: true, thStyle: 'width: 135px'},
				{ key: '$btns', label: '', thStyle: 'width: 105px'},
			],
			canCreate: inputData.isTrader,
			apiUri: `placement/${this.$route.params.id}/plan`,
			defaultParams,
			items: this.week.plans.map(p => this.setPlanItemContext(p))
		});
	},
	methods: {
		deleteItem(item) {
			if (!window.confirm(`Opravdu smazat plán?`)) {
				return;
			}
			return this.fetchApi(`/${this.apiUri}/${item.id}`, {
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
				}
			})
			.then(_ => {
				this.$emit('delete', item);
			})
			.catch((err) => console.error(err.message||err.statusText||err))
		}
	}
}
</script>
