<template>
	<div class="container-fluid">
		<div class="col-sm-8 col-lg-6 mx-auto">
			<h2>Login</h2>
			<form @submit.prevent="login">
				<div class="form-group row">
					<label class="col-xs-12 col-md-5 col-lg-4 col-form-label" for="usr">Email:</label>
					<div class="col-xs-12 col-md-7 col-lg-8">
						<b-form-input v-model="email"
							type="email"
							placeholder="Emailová adresa.."
							@input="error = undefined"
							id="usr"
							:state="!error ? (email ? /\S+@\S+\.\S+/.test(email) : null) : false">
						</b-form-input>
					</div>
				</div>
				<div class="form-group row">
					<label class="col-xs-12 col-md-5 col-lg-4 col-form-label" for="pwd">Heslo</label>
					<div class="col-xs-12 col-md-7 col-lg-8">
						<b-form-input v-model="password"
							type="password"
							placeholder="Heslo.."
							@input="error = undefined"
							id="pwd"
							:state="!error ? (password ? true : null) : false">
						</b-form-input>
					</div>
				</div>
				<div class="row">
					<div v-if="error&&/\S+@\S+\.\S+/.test(email)" class="col-auto">
						<a href="#" @click.prevent="requirePwdChange">Obnovit heslo</a>
					</div>
					<div class="col text-right">
						<button class="btn btn-primary" type="submit" :disabled="!!(!email||!password||error)">Přihlásit</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
export default {
	data () {
		return {
			email: '',
			password: '',
			error: undefined
		}
	},
	methods: {
		login () {
			const params = new URLSearchParams();
			params.set("username", this.email);
			params.set("password", this.password);

			this.fetchApi("/login", {
				method: "POST",
				body: params.toString(),
				credentials: 'include',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
				}
			})
			.then((user) => {
				localStorage.setItem("username", user.email);
				localStorage.setItem("userId", user.id);
				localStorage.setItem("userRole", user.role);
				this.$root.username = user.email;
				this.$root.msg = {
					value: 'Přihlášen',
					type: 'alert-success'
				}
				this.$router.replace(`${user.role ? this.$route.query.redirect||'/campaign' : '/'}`);
			})
			.catch((err) => {
				localStorage.removeItem("username");
				localStorage.removeItem("userId");
				localStorage.removeItem("userRole");
				this.$root.username = '';
				this.error = err.message||err.statusText||err;
				console.error(this.error);
				return;
			});
		},
		requirePwdChange() {
			if (!window.confirm(`Opravdu chcete nastavit nové heslo?`)) {
				return;
			}
			const params = new URLSearchParams();
			params.set("username", this.email);
			this.fetchApi("/set-pwd", {
				method: "POST",
				body: params.toString(),
				credentials: 'include',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
				}
			}, true)
			.then(() => {
				this.$root.msg = {
					value: 'Byl vám odeslán email s dalšími instrukcemi',
					type: 'alert-success'
				}
			})
			.catch((err) => {
				console.error(err);
			});
		}
	}
}
</script>
