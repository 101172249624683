import { debounce } from "lodash";
import looseEqual from "bootstrap-vue/src/utils/loose-equal";

export default {
	props: {
		value: [String, Object, Array],
		searchProvider: Function,
		required: Boolean,
		id: String,
		size: String,
		placeholder: String,
		disabled: Boolean,
		objectKey: String,
		formatValue: Function,
		formatOption: Function,
		state: {
			type: Boolean,
			default: null
		},
		onlySelected: {
			type: Boolean,
			default: true
		},
		limit: {
			type: Number,
			default: 1
		},
		debounce: {
			type: Number,
			default: 500
		}
	},
	data() {
		return {
			typeaheadFocused: false,
			items: [],
			tags: [],
			tagState: this.state,
			tagStrings: [],
			newOption: null,
			searchVal: "",
			loading: false
		};
	},
	directives: {
		clickOutside: {
			bind: function(el, binding, vnode) {
				el.clickOutsideEvent = function(event) {
					// here I check that click was outside the el and his children
					if (!(el == event.target || el.contains(event.target))) {
						// and if it did, call method provided in attribute value
						vnode.context[binding.expression](event);
					}
				};
				document.body.addEventListener("click", el.clickOutsideEvent);
			},
			unbind: function(el) {
				document.body.removeEventListener("click", el.clickOutsideEvent);
			}
		}
	},
	methods: {
		debounceProvider: debounce(async function() {
			if (this.searchVal && this.searchVal.length > 1) {
				this.items = (await this.searchProvider(this.searchVal)) || [];
				this.typeaheadFocused = true;
				this.loading = false;
			}
		}, 1000),
		onFocus() {
			this.typeaheadFocused = true;
		},
		onBlur() {
			if (!this.typeaheadFocused) {
				return;
			}
			this.typeaheadFocused = false;
			if (!this.tags.length) {
				this.$refs.formTags.newTag = "";
				if (this.required) {
					this.tagState = false;
				}
			}
		},
		onTagClick(option) {
			this.items = [];
			this.tags.push(option);
			this.searchVal = "";
			this.$refs.formTags.addTag(this.getFormatValue(option));
		},
		getFormatValue(option) {
			const formatFc = this.formatValue || this.formatOption;
			return formatFc && typeof option === "object" ? formatFc(option) : option;
		},
		getFormatOption(option) {
			const formatFc = this.formatOption || this.formatValue;
			return formatFc && typeof option === "object" ? formatFc(option) : option;
		},
		boldSearchString(input, str) {
			return input ? str.replace(new RegExp(`(${input})`, "ig"), "<strong>$1</strong>") : input;
		},
		onRemoveTag(val) {
			return true;
		},
		onInput(val) {
			if (!val || val.length < 2) {
				this.items = [];
				this.searchVal = "";
				this.tagState = false;
				return;
			} else if (this.tags.length < this.limit) {
				this.tagState = this.state;
				this.searchVal = val;
				this.loading = true;
				this.debounceProvider();
			}
		}
	},
	watch: {
		value: {
			immediate: true,
			handler(val) {
				const tags = val ? (Array.isArray(val) ? val : [val]) : [];
				this.tags = tags.filter(tag => {
					if (typeof tag !== "object" && typeof tag !== "string") {
						return false;
					}
					if (typeof tag === "object" && !this.formatValue && !this.formatOption) {
						console.error("Object model must- be followed with any/both formatter properties.");
						return false;
					}
					return true;
				});
				this.tagStrings = this.tags.map(tag => {
					return typeof tag === "object" ? this.getFormatValue(tag) : tag;
				});
			}
		},
		state(val) {
			this.tagState = val === false || this.tagState === false ? false : val;
		},
		tagStrings(newVal, oldVal) {
			if (!newVal.length && this.required) {
				this.tagState = false;
			}
			this.searchVal = "";
			this.items = [];
			if (newVal.length < oldVal.length) {
				const removedStr = oldVal.find(str => !newVal.includes(str));
				const removedTag = this.tags.find(
					tag => (this.getFormatValue ? this.getFormatValue(tag) : tag) === removedStr
				);
				this.$emit("remove", removedTag);
			} else if (newVal.length > oldVal.length) {
				const addedStr = newVal.find(str => !oldVal.includes(str));
				const addedTag = this.tags.find(
					tag => (this.getFormatValue ? this.getFormatValue(tag) : tag) === addedStr
				);
				this.$emit("add", addedTag);
			}
			this.tags = this.tagStrings.map(str => {
				const tag = this.tags.find(tag => (this.getFormatValue ? this.getFormatValue(tag) : tag) === str);
				return tag ? tag : this.objectKey ? { [this.objectKey]: str } : str;
			});
			this.$emit("change", this.tags);
		}
	}
};
