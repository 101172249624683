<template>
	<b-form-input type="text" v-model="displayValue"
		:state="valid === false ? false : state"
		@blur="onFocus"
		@focus="onBlur"
		@change="onChange"
		:id="id"
		:size="size"
		:placeholder="placeholder||''"
		:disabled="disabled||false"
		:required="required||false"/>
	</b-form-input>
</template>

<script>
export default {
	props: ["value", "required", "id", "size", "placeholder", "suffix", "disabled", "state", "int"],
	data() {
		return {
			isInputActive: false,
			valid: null
		}
	},
	computed: {
		displayValue: {
			get: function() {
				if (!this.value) return '';
				return this.isInputActive ? this.value.toString().replace('.',',') : this.getFormattedNumber(this.value) + (this.suffix||'')
			},
			set: function(modifiedValue) {
				if (this.disabled) return;
				const parseFc = this.int ? parseInt : parseFloat;
				const newValue = parseFc(modifiedValue.replace(' ', '').replace(',','.'))
				this.valid = newValue ? true : (!this.required && !modifiedValue ? null : false);
				this.$emit('input', isNaN(newValue) ? null : newValue);
			}
		}
	},
	methods: {
		onFocus(evt) {
			this.isInputActive=false;
			this.$emit('focus', evt);
		},
		onBlur(evt) {
			this.isInputActive=true;
			this.$emit('blur', evt);			
		},
		onChange(evt) {
			this.$emit('change', evt);			
		}
	}
}
</script>