<template>
	<b-modal id="placement-plan-modal"
		size="xl"
		:visible="visible"
		@hide="$emit('hide', $event)"
		@hidden="$emit('hidden')"
		dialog-class="container-fluid"
		centered>
		<template v-slot:modal-header="{ close }">
			<h5 class="text-wrap mb-0">Plán kampaně {{ item.$campaign ? `(id: ${item.$campaign.id}) ` : ''  }}pro {{ placement.description }}</h5>
			<button type="button" aria-label="Zavřít" class="close" @click="close()">×</button>
		</template>
		<template v-slot:modal-footer="{ close, cancel }">
			<button class="btn btn-outline-primary" type="button" @click="cancel()">Zavřít</button>
			<button class="btn btn-primary"
				type="button"
				@click="submitItem(`placement/${placement.id}/plan`, item, false, close)">
				Uložit
			</button>
		</template>
		<div class="form-row">
			<div class="form-group col-12 col-md-6">
				<label class="mb-1" :for="getInputId(item, 'placements_campaigns_id')">Kampaň:</label>
				<b-form-select v-model="item.$inputs.placements_campaigns_id"
					:id="getInputId(item, 'placements_campaigns_id')"
					:options="campaignOptions"
					@change="onCampaignChange(item)"
					:state="item.$states.placements_campaigns_id"
					class="d-block"
					size="sm"
					style="font-size: 13px;"
					:required="required.indexOf('placements_campaigns_id') > -1">
					<template v-slot:first>
						<b-form-select-option :value="null" disabled>-- Vyberte kampaň --</b-form-select-option>
					</template>
				</b-form-select>
				<!-- z nejakeho duvodu nereaktivni i kdyz je trader_id v $errors datech od zacatku -->
				<div class="invalid-tooltip truncate" v-if="item.$errors.placements_campaigns_id">{{ item.$errors.placements_campaigns_id }}</div>
			</div>
			<div class="form-group col-4 col-md-3 col-xl-2">
				<label class="mb-1" :for="getInputId(item, 'date_from')">Datum od:</label>
				<b-form-datepicker v-model="item.$inputs.date_from"
					type="date"
					placeholder="Datum od.."
					:id="getInputId(item, 'date_from')"
					size="sm"
					locale="cs"
					:date-format-options="dateFormat"
					:min="item.$minDateFrom"
					:max="item.$maxDateFrom"
					:required="required.indexOf('date_from') > -1"
					@input="dateFromToValidator(item, 'date_from')"
					:state="item.$states.date_from"
					:disabled="!item.$inputs.placements_campaigns_id"
					label-reset-button="Smazat"
					label-close-button="Zavřít"
					start-weekday="1"
					reset-button
					close-button>
				</b-form-datepicker>
				<div class="invalid-tooltip truncate" v-if="item.$errors.date_from">{{ item.$errors.date_from }}</div>
			</div>
			<div class="form-group col-4 col-md-3 col-xl-2">
				<label class="mb-1" :for="getInputId(item, 'date_to')">Datum do:</label>
				<b-form-datepicker v-model="item.$inputs.date_to"
					type="date"
					placeholder="Datum do.."
					:id="getInputId(item, 'date_to')"
					size="sm"
					locale="cs"
					:min="item.$minDateTo"
					:max="item.$maxDateTo"
					:required="required.indexOf('date_to') > -1"
					:date-format-options="dateFormat"
					@input="dateFromToValidator(item, 'date_to')"
					:state="item.$states.date_to"
					:disabled="!item.$inputs.placements_campaigns_id"
					label-reset-button="Smazat"
					label-close-button="Zavřít"
					start-weekday="1"
					reset-button
					close-button>
				</b-form-datepicker>
				<div class="invalid-tooltip truncate" v-if="item.$errors.date_to">{{ item.$errors.date_to }}</div>
			</div>
			<div class="form-group col-4 col-md-3 col-xl-2">
				<label class="mb-1" :for="getInputId(item, 'impression')">Počet impresí:</label>
				<formatted-num-input v-model="item.$inputs.impression"
					placeholder="Zadejte číslo.."
					size="sm"
					:int="true"
					:id="getInputId(item, 'impression')"
					:state="item.$states.impression"
					:readOnly="!item.$inputs.placements_campaigns_id"
					@input="onGenericInput(item, 'impression', true)"
					:required="required.indexOf('impression') > -1">
				</formatted-num-input>
				<div class="invalid-tooltip truncate" v-if="item.$errors.impression">{{ item.$errors.impression }}</div>
			</div>
		</div>
	</b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import weekPlanMixin from '../mixins/week_plan_mixin';
import inputMixin from '../mixins/input_mixin';
export default {
	components: {
		BModal
	},
	mixins: [inputMixin, weekPlanMixin],
	data() {
		return Object.assign(this.inputData(), this.planData(), {
			item: this.setPlanItemContext(this.plan)
		});
	},
	props: {
		plan: {
			type: Object,
			default: () => { return {} }
		},
		visible: {
			type: Boolean,
			default: false
		},
		removeItem: {
			type: Boolean,
			default: false
		},
	},
	methods: {
	}
}
</script>
