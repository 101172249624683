<template>
	<div class="container-fluid">
		<div class="standalone-header" v-if="!parent">
			<h2 class="mb-3">Uživatelé</h2>
		</div>
		<h4 class="mb-3" v-else>Uživatelé</h4>
		<!-- Main table element -->
		<div class="row mb-2">
			<div class="form-group col-md-10">
				<input type="text"
					class="form-control"
					v-model="searchFilter"
					placeholder="Hledat v názvu ..."
					id="search"
					v-on:change="onFiltered">
			</div>
		</div>
		<b-table ref="table"
			show-empty
			stacked="md"
			:items="rowProvider"
			:fields="fields"
			:current-page="currentPage"
			:per-page="perPage"
			:filter="filter"
			:sort-by.sync="sortBy"
			:sort-desc.sync="sortDesc"
			:tbody-class="'text-nowrap'"
			:tbody-tr-class="rowClass"
			:empty-text="'Žádní uživatelé k zobrazení.'"
			:empty-filtered-text="'Žádný uživatel nevyhovuje vybraným kritériím.'" 
			@filtered="onFilteredRefresh"
		>
			<template v-slot:cell(traders)="{item}">
				<b-form-tag-typeahead
					v-model="item.$inputs.traders"
					object-key="alias"
					:format-value="t => `${t.alias}${t.name ? ` (${t.name})` : '' }`"
					:id="getInputId(item, 'traders')"
					:search-provider="searchTraderProvider(item)"
					:limit="3"
					size="sm"
					placeholder="Zadejte alias.."
					:state="item.$states.traders"
					@remove="onRemoveManyToManyRelation('traders', item, $event)"
					@add="onAddManyToManyRelation('traders', item, $event)">
				</b-form-tag-typeahead>
				<div class="invalid-tooltip truncate" v-if="item.$errors.traders">{{ item.$errors.traders }}</div>
			</template>
			<template v-slot:cell(role)="{item}">
				<b-form-select v-model="item.$inputs.role" size="sm">
					<b-form-select-option :value="null" >Źádná</b-form-select-option>
					<b-form-select-option value="ghost">Náhled</b-form-select-option>
					<b-form-select-option value="trader">Obchodník</b-form-select-option>
					<b-form-select-option v-if="isSuperAdmin" value="admin">Admin</b-form-select-option>
					<b-form-select-option v-if="isSuperAdmin" value="accountant">Účetní</b-form-select-option>
					<b-form-select-option v-if="isSuperAdmin" value="superadmin">SuperAdmin</b-form-select-option>
				</b-form-select>
			</template>
			<template v-slot:cell($btns)="{item}">
				<table-buttons :item="item"
					:api-uri="apiUri"
					:on-item-state="itemChangedAndValid"
					:loading="item.$loading"
					:reset-item="resetItemInputs"
					:submit-item="onSubmit">
				</table-buttons>
			</template>
			<template v-slot:table-busy>
				<div class="text-center text-secondary my-2">
					<b-spinner class="align-middle"></b-spinner>
					<strong>Načítám...</strong>
				</div>
			</template>
		</b-table>

		<div class="row" v-if="!parent">
			<div class="col-md-6">
				<b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" />
			</div>
		</div>
	</div>
</template>

<script>
import { BFormSelect } from 'bootstrap-vue';
import inputMixin from '../mixins/input_mixin';
import tableMixin from '../mixins/table_mixin';

const defaultParams = {
	offset: 0,
	limit: 20,
	sort: 'email',
	desc: 0
}

const filter = {
	search: {
		type: String
	},
	id: {
		type: Number
	}
}

export default {
	components: {
		BFormSelect
	},
	mixins: [tableMixin, inputMixin],
	props: Object.assign(tableMixin.methods.getDefaultProps(defaultParams), filter),
	data () {
		return Object.assign(this.tableData(filter), this.inputData(), {
			fields: [
				{ key: 'email', label: 'Email', sortable: !this.parent, thStyle: 'width: 300px', tdClass: 'align-middle'},
				{ key: 'role', label: 'Role', thStyle: 'width: 200px'},
				{ key: 'traders', label: 'Obchodník'},
				{ key: '$btns', label: '', thStyle: 'width: 105px'},
			],
			apiUri: 'user',
			required: [],
			isSuperAdmin: this.hasMinRights('superadmin'),
			inputs: {
				role: null,
				traders: null
			},
			defaultParams
		});
	},
	methods: {
		async onSubmit(type, item) {
			const logout = Number(localStorage.getItem("userId")) === item.id && item.$inputs.role !== item.role;
			if (!await this.submitItem(type, item, true)) {
				return;
			}
			if (logout) {
				this.$router.push({path: '/logout'})
			}
		},
		searchTraderProvider(item) {
			return (searchValue) => {
				return this.fetchApi(`/trader?search=${encodeURI(searchValue)}`)
					.then(data => {
						if (!data || !data.count) {
							item.$errors.traders = 'Žádné výsledky';
							return [];
						}
						item.$errors.traders = '';
						return data.rows;
					})
					.catch(err => {
						err = err.message||err.statusText||err;
						item.$errors.traders = err;
						return [];
					});
			}
		}
	}
}
</script>
