import { debounce } from "lodash";

export default {
	methods: {
		searchNames: debounce(async function(item, name) {
			const chainRequest = req => {
				return req.then(data => {
					if (Array.isArray(data)) {
						if (data.length) {
							return data;
						}
					} else if (data && data.rows) {
						if (data.rows.length) {
							return data.rows;
						}
					} else if (data && data.id) {
						return data;
					}
					return null;
				});
			};

			item.$nameItems = await chainRequest(this.fetchApi(`/company?name=${encodeURI(name)}`, null, false))
				.then(data => {
					if (!data && name.length > 2) {
						return chainRequest(this.fetchApi(`/ares?name=${encodeURI(name)}`, null, false));
					} else if (data && !data.some(c => c.id === item.id)) {
						throw new Error(
							`Firma s podobným názvem už existuje("${data[0].name}"). Zvolte jiný název nebo použijte existujicí subjekt.`
						);
					}
					return null;
				})
				.then(async data => {
					if (data) {
						const queryStr = data.reduce(
							(acc, c) => (acc += `${acc ? "&" : "?"}name=${encodeURI(c.name)}&ico=${c.ico}`),
							""
						);
						const localComps = await chainRequest(this.fetchApi(`/company${queryStr}`, null, false));
						if (localComps) {
							data = data.filter(c => !localComps.some(l => l.name === c.name || l.ico === c.ico));
						}
					}
					return data || [];
				})
				.catch(err => {
					item.$errors.name = err.message || err.statusText || err;
					item.$states.name = false;
					return [];
				});
			item.$loading = false;
		}, 1000),
		setAdditionalItemCtx(item) {
			item.$nameItems = [];
			item.$nameFocused = false;
		},
		onNameInput(item, name) {
			this.onTextInput(item, "name", name, true);
			if (item.$states.name) {
				item.$loading = true;
				this.searchNames(item, name);
			}
		},
		getFormatOption(option) {
			return `${option.name} (IČO: ${option.ico})`;
		},
		onToggleNameFocus: debounce(function(item, isFocus) {
			if (!isFocus) {
				this.onTextBlur(item, "name", true);
			}
			item.$nameFocused = isFocus;
		}, 250),
		async checkUidExists(item, key) {
			if (!item.$states[key] || !item.$inputs[key]) {
				return;
			}
			const chainRequest = req => {
				return req.then(data => {
					if (Array.isArray(data)) {
						if (data.length) {
							return data[0];
						}
					} else if (data && data.rows) {
						if (data.rows.length) {
							return data.rows[0];
						}
					} else if (data && data.id) {
						return data;
					}
					return null;
				});
			};
			item.$loading = true;
			const isICO = key === "ico";
			await chainRequest(this.fetchApi(`/company/${key}/${item.$inputs[key]}`, null, false))
				.then(data => {
					if (data) {
						throw new Error(`Firma s tímto ${!isICO ? "D" : ""}IČem už existuje.`);
					}
					return isICO ? chainRequest(this.fetchApi("/ares?ico=" + item.$inputs.ico, null, false)) : null;
				})
				.then(async data => {
					if (data) {
						const localComp = await chainRequest(this.fetchApi(`/company?name=${data.name}`, null, false));
						if (localComp && localComp.id !== item.id) {
							if (
								!window.confirm(
									`Nalezen jiný lokalní subjekt ("${data.name}", ičo: ${localComp.ico ||
										"žádné"}). Aktualizovat daty z ARESu?`
								)
							) {
								return;
							}
							await this.fetchApi(`/company/${localComp.id}`, {
								method: "PATCH",
								headers: {
									"Content-Type": "application/json"
								},
								body: JSON.stringify(data)
							})
								.then(_ => this.$emit("update", localComp))
								.catch(err => {
									console.error(err.message || err.statusText || err);
								});
							return;
						}
						if (window.confirm(`Subjekt nalezen v ARESu ("${data.name}") Převzít data?`)) {
							return this.onSelectedOption(item, data);
						}
					} else if (isICO) {
						this.$root.msg = {
							value: "Subjekt nenalezen v ARESu.",
							type: "alert-warning"
						};
					}
				})
				.catch(err => {
					item.$errors[key] = err.message || err.statusText || err;
					item.$states[key] = false;
					return null;
				});
			item.$loading = false;
		},
		uidValidator(item, value, key, validator) {
			// kontrola validity ICO/DIC
			item.$errors[key] = validator(item, value);
			switch (item.$errors[key]) {
				case null:
					item.$states[key] = null;
					break;
				case "":
					item.$states[key] = true;
					break;
				default:
					item.$states[key] = false;
			}
			return item.$states[key];
		}
	}
};
