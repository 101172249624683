<template>
	<b-modal id="placement-modal"
		size="xl"
		:visible="visible"
		@hide="$emit('hide', $event)"
		@hidden="$emit('hidden')"
		dialog-class="container-fluid"
		centered>
		<template v-slot:modal-header="{ close }">
			<h5 v-if="item && item.id" class="text-wrap mb-0">Umístění: {{ item.description }} (id:{{ item.id }})</h5>
			<h5 v-else class="mb-0">Nové umístění</h5>
			<button type="button" aria-label="Zavřít" class="close" @click="close()">×</button>
		</template>
		<template v-slot:modal-footer="{ close, cancel }">
			<button v-if="removeItem && isSuperAdmin"
				class="btn btn-outline-danger mr-auto"
				type="button"
				@click="deleteItemAndUpdatePlacements('placement', item, close, moveTo)">
				Smazat
			</button>
			<button class="btn btn-outline-primary" type="button" @click="cancel()">Zavřít</button>
			<button v-if="!removeItem || !isSuperAdmin"
				class="btn btn-primary"
				type="button"
				@click="submitItemAndUpdatePlacements('placement', item, false, close)">
				Uložit
			</button>
		</template>
		<div v-if="removeItem && isSuperAdmin" class="form-row">
			<div class="form-group col-12 col-md-2 col-xl-2">
				<label class="mb-1" :for="getInputId(item, 'move-to')">Sloučit do:</label>
				<b-form-tag-typeahead
					v-model="moveTo"
					:format-value="(obj) => obj.description"
					:id="getInputId(item, 'move-to')"
					:search-provider="searchModelProvider('placement', item.id)"
					:limit="1"
					size="sm"
					placeholder="Zadejte název.."
					@remove="moveTo = null"
					@add="moveTo = $event">
				</b-form-tag-typeahead>
			</div>
		</div>	
		<div v-else  class="form-row">
			<div class="form-group col-9 col-xl-10">
				<label class="mb-1" :for="getInputId(item, 'description')">Název:</label>
				<b-form-input v-model="item.$inputs.description"
					placeholder="Zadejte název.."
					:id="getInputId(item, 'description')"
					size="sm"
					:state="item.$states.description"
					@input="getDescValidator('placement', item)($event)"
					:required="true">
				</b-form-input>
				<div class="invalid-tooltip truncate" v-if="item.$errors.description">{{ item.$errors.description }}</div>
			</div>
			<div class="form-group col-3 col-xl-2">
				<label class="mb-1" :for="getInputId(item, 'week_plan_impression')">Týdenní plán:</label>
				<formatted-num-input v-model="item.$inputs.week_plan_impression"
					placeholder="Zadejte číslo.."
					:id="getInputId(item, 'week_plan_impression')"
					size="sm"
					:state="item.$states.week_plan_impression"
					@input="onGenericInput(item, 'week_plan_impression', false)">
				</formatted-num-input>
				<div class="invalid-tooltip truncate" v-if="item.$errors.week_plan_impression">{{ item.$errors.week_plan_impression }}</div>
			</div>
		</div>
	</b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import placementMixin from '../mixins/format_placement_mixin';
import inputMixin from '../mixins/input_mixin';
export default {
	components: {
		BModal
	},
	mixins: [inputMixin, placementMixin],
	data() {
		return Object.assign(this.inputData(), {
			moveTo: null,
			required: ['description'],
			descriptions: this.parent.description ? [this.parent.description] : [],
			item: this.setItemContext(Object.assign({}, this.parent), {
				description: null,
				week_plan_impression: null
			})
		});
	},
	props: {
		parent: {
			type: Object,
			default: () => { return {} }
		},
		visible: {
			type: Boolean,
			default: false
		},
		removeItem: {
			type: Boolean,
			default: false
		},
	},
	methods: {
		descriptionValidator(tag) {
			// kontrola validity aliasu
			return (!/^[a-zA-Z0-9]{2,6}$/.test(tag))
		},
		checkDescriptionExists(val) {
			return this.fetchApi(`/placement?description=${encodeURI(val)}`)
				.then(data => {
					if (data && data.rows) {
						this.item.$errors.description = 'Umístění už existuje.'; // neprojde validatorem takze zcervena
						return '';
					}
					return val;
				})
				.catch(err => {
					err = err.message||err.statusText||err;
					this.item.$errors.description = 'Error:' + err; // pro jistotu aby neproslo validatorem
					return '';
				});
		},
		async submitItemAndUpdatePlacements(apiUri, item, noMsg, callbackFc) {
			if (item.$inputs.week_plan_impression !== item.week_plan_impression) {
				const defaultCb = callbackFc;
				callbackFc = async() => {
					await this.setPlannedPlacements.call(this)
					defaultCb();
				}
			}
			await this.submitItem(apiUri, item, noMsg, callbackFc);
		},
		deleteItemAndUpdatePlacements(apiUri, item, noMsg, callbackFc) {
			if (item.week_plan_impression) {
				const defaultCb = callbackFc;
				callbackFc = async() => {
					await this.setPlannedPlacements.call(this)
					defaultCb();
				}				
			}
			this.deleteItem(apiUri, item, noMsg, callbackFc);
		}
	}
}
</script>
