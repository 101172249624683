<template>
	<div class="container-fluid">
		<div class="standalone-header" v-if="!parent">
			<h2 class="mb-3">Formáty
				<button type="button" class="btn p-0 mb-1 align-middle" v-if="!modalItem && isAdmin" @click="modalItem = {};modalVisible=true">
					<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20px" height="20px" x="0px" y="0px" viewBox="0 0 42 42" xml:space="preserve"><path fill="#62cb35" d="M37.059,16H26V4.941C26,2.224,23.718,0,21,0s-5,2.224-5,4.941V16H4.941C2.224,16,0,18.282,0,21s2.224,5,4.941,5H16v11.059 C16,39.776,18.282,42,21,42s5-2.224,5-4.941V26h11.059C39.776,26,42,23.718,42,21S39.776,16,37.059,16z"/></svg>
				</button>
			</h2>		</div>
		<h4 class="mb-3" v-else>Formáty</h4>
		<!-- Main table element -->
		<div class="row mb-2">
			<div class="form-group col-md-10">
				<input type="text"
					class="form-control"
					v-model="searchFilter"
					placeholder="Hledat v názvu ..."
					id="search"
					v-on:change="onFilteredRefresh">
			</div>
		</div>
		<b-table ref="table"
			show-empty
			stacked="md"
			:items="rowProvider"
			:fields="fields"
			:current-page="currentPage"
			:per-page="perPage"
			:filter="filter"
			:sort-by.sync="sortBy"
			:sort-desc.sync="sortDesc"
			:tbody-class="'text-nowrap'"
			:tbody-tr-class="rowClass"
			:empty-text="'Žádní obchodníci k zobrazení.'"
			:empty-filtered-text="'Žádný obchodník nevyhovuje vybraným kritériím.'" 
			@filtered="onFiltered"
			no-sort-reset
		>
			<template v-slot:cell(description)="{item}">
				<div class="position-relative">
					<b-form-input v-model="item.$inputs.description"
						placeholder="Zadejte název.."
						:id="getInputId(item, 'description')"
						size="sm"
						:state="item.$states.description"
						@input="getDescValidator('format', item)($event)"
						@blur="onTextBlur(item, 'description')"
						:required="true">
					</b-form-input>
					<b-spinner small variant="secondary" label="Načítám" v-if="item.$loading" style="position: absolute;right: 8px;top: 8px;opacity: 0.5;"></b-spinner>
					<div class="invalid-tooltip truncate" v-if="item.$errors.description">{{ item.$errors.description }}</div>
				</div>
			</template>
			<template v-slot:cell($btns)="{item}">
				<table-buttons :item="item"
					:api-uri="apiUri"
					:on-item-state="itemChangedAndValid"
					:loading="item.$loading"
					:reset-item="resetItemInputs"
					:remove-item="toggleRemoveItem"
					:submit-item="submitItem"
					:remove-enabled="isSuperAdmin">
				</table-buttons>
			</template>
			<template v-slot:table-busy>
				<div class="text-center text-secondary my-2">
					<b-spinner class="align-middle"></b-spinner>
					<strong>Načítám...</strong>
				</div>
			</template>
		</b-table>
		<div class="row" v-if="!parent">
			<div class="col-md-6">
				<b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" />
			</div>
		</div>

		<format-modal-form v-if="!parent && modalItem"
			:parent="modalItem"
			:visible="modalVisible"
			:remove-item="removeItem"
			@hide="hideModal($event)"
			@hidden="modalItem=null;removeItem=false"
			@update="onRowChanged($event)"
			@delete="onRowChanged($event, true)"
			@create="onRowChanged($event, true)">
		</format-modal-form>
	</div>
</template>

<script>
import FormatModalForm from '../forms/format_mform.vue';
import formatMixin from '../mixins/format_placement_mixin';
import inputMixin from '../mixins/input_mixin';
import tableMixin from '../mixins/table_mixin';

const defaultParams = {
	offset: 0,
	limit: 20,
	sort: 'description',
	desc: 0
}

const filter = {
	search: {
		type: String
	},
	id: {
		type: Number
	}
};

export default {
	components: {
		FormatModalForm
	},
	mixins: [tableMixin, inputMixin, formatMixin],
	props: Object.assign(tableMixin.methods.getDefaultProps(defaultParams), filter),
	data () {
		return Object.assign(this.tableData(filter), this.inputData(), {
			fields: [
				{ key: 'description', label: 'Název', sortable: !this.parent},
				{ key: '$btns', label: '', thStyle: 'width: 105px'},
			],
			apiUri: 'format',
			required: ['description'],
			inputs: {
				description: null
			},
			defaultParams
		});
	}
}
</script>
