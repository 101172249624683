<template>
	<div class="container-fluid">
		<div class="col-sm-8 col-lg-6 mx-auto">
			<h2>Nastavení hesla</h2>
			<form @submit.prevent="submit">
				<div class="form-group row">
					<label class="col-xs-12 col-md-5 col-lg-4 col-form-label" for="pwd1">Nové heslo:</label>
					<div class="col-xs-12 col-md-7 col-lg-8">
						<div class="position-relative">
							<b-form-input
								v-model="password1"
								type="password"
								placeholder="Zadejte nové heslo.."
								@input="onPwdInput('password1')"
								id="pwd1"
								:state="password1Error !== null ? !password1Error : null"
							>
							</b-form-input>
							<div class="invalid-tooltip truncate" v-if="password1Error">{{ password1Error }}</div>
						</div>
					</div>
				</div>
				<div class="form-group row">
					<label class="col-xs-12 col-md-5 col-lg-4 col-form-label" for="pwd2">Kontrola hesla:</label>
					<div class="col-xs-12 col-md-7 col-lg-8">
						<div class="position-relative">
							<b-form-input
								v-model="password2"
								type="password"
								placeholder="Zadejte stejné heslo znovu.."
								@input="onPwdInput('password2')"
								id="pwd2"
								:state="password2Error !== null ? !password2Error : null"
							>
							</b-form-input>
							<div class="invalid-tooltip truncate" v-if="password2Error">{{ password2Error }}</div>
						</div>
					</div>
				</div>
				{{ password1Error }} {{ password2Error }}
				<button
					class="btn float-right"
					v-bind:class="error ? 'btn-danger' : 'btn-primary'"
					type="submit"
					:disabled="
						!!(
							password1Error ||
							password1Error === null ||
							password2Error ||
							password2Error === null ||
							error
						)
					"
				>
					{{ error || "Nastavit" }}
				</button>
			</form>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			password1: "",
			password2: "",
			password1Error: null,
			password2Error: null,
			error: "",
		};
	},
	methods: {
		onPwdInput(key) {
			if (!this[key]) {
				this[key + "Error"] = "Heslo je povinné.";
			} else if (this[key].search(/\d/) === -1) {
				this[key + "Error"] = "Heslo musí obsahovat znak 0-9.";
			} else if (this[key].search(/[a-z]/) === -1) {
				this[key + "Error"] = "Heslo musí obsahovat znak a-z.";
			} else if (this[key].search(/[A-Z]/) === -1) {
				this[key + "Error"] = "Heslo musí obsahovat znak A-Z.";
			} else if (this[key].search(/[^a-zA-Z0-9!@#$%^&*()_+]/) !== -1) {
				this[key + "Error"] = "Heslo obsahuje nevalidní znak. Povolené: !@#$%^&*()_+";
			} else if (this[key].length < 8) {
				this[key + "Error"] = "Heslo je příliš krátké (min 8)";
			} else if (this[key].length > 50) {
				this[key + "Error"] = "Heslo je příliš dlouhé (max 50)";
			} else {
				this[key + "Error"] = "";
			}
			if (this.password1Error !== null && this.password2Error !== null) {
				this.error = this.password1 !== this.password2 ? "Hesla se neshodují" : "";
			}
		},
		submit() {
			const params = new URLSearchParams();
			params.set("password", this.password1);
			const token = this.$route.path.substring(this.$route.path.lastIndexOf("/") + 1);
			this.fetchApi(
				"/set-pwd/" + token,
				{
					method: "PATCH",
					body: params.toString(),
					credentials: "include",
					headers: {
						"Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
					},
				},
				true
			)
				.then(() => {
					this.$root.msg = {
						value: "Můžete se přihlásit s novým heslem.",
						type: "alert-success",
					};
					this.$router.replace("/login");
				})
				.catch((err) => {
					this.$router.replace("/");
				});
		},
	},
};
</script>
