import { debounce, isEqual } from "lodash";

export default {
	methods: {
		inputData() {
			return {
				isSuperAdmin: this.hasMinRights("superadmin"),
				isAdmin: this.hasMinRights("admin"),
				isAccountant: localStorage.getItem("userRole") === "accountant",
				isTrader: this.hasMinRights("trader"),
			};
		},
		setItemContext(item, inputs) {
			Object.assign(item, {
				$inputs: {},
				$states: {},
				$errors: {},
			});
			for (const key in inputs) {
				// skip internals and missing properties
				if (!(key in item) && key.charAt(0) !== "$") {
					item[key] = inputs[key];
				}
				// u objektu chceme aspon shallow kopii
				if (Array.isArray(item[key])) {
					item.$inputs[key] = item[key].map((v) => v);
				} else if (item[key] instanceof Object) {
					item.$inputs[key] = Object.assign({}, item[key]);
				} else {
					item.$inputs[key] = item[key];
				}
				item.$states[key] = null;
				item.$errors[key] = null;
			}
			item.$diff = null;
			item.$loading = false;
			item.$submit = false;
			this.setAdditionalItemCtx && this.setAdditionalItemCtx(item);
			return item;
		},
		dateValidator(item, key) {
			const required = this.required.indexOf(key) > -1;
			if (!required && !item.$inputs[key]) {
				item.$errors[key] = item.$states[key] = null;
				return;
			}

			if (item.$inputs[key] === item[key]) {
				item.$errors[key] = item.$states[key] = null;
				return;
			}

			if (!item.$inputs[key] || !/^\d{4}-\d{2}-\d{2}$/.test(item.$inputs[key])) {
				item.$errors[key] = "Nevalidní datum";
				item.$states[key] = false;
				return;
			}

			item.$states[key] = true;
			item.$errors[key] = "";
		},
		onGenericInput(item, key, required, checkFc) {
			const err = (checkFc && checkFc()) || (required && !item.$inputs[key] && "Toto pole je povinné") || "";
			if (err) {
				item.$errors[key] = err;
				item.$states[key] = false;
				return;
			}
			if (isEqual(item.$inputs[key], item[key])) {
				item.$errors[key] = item.$states[key] = null;
				return;
			}
			item.$errors[key] = "";
			item.$states[key] = true;
		},
		resetItemInputs(item) {
			for (const key in item.$inputs) {
				// skip internals and missing properties
				if (key in item) {
					item.$inputs[key] = item[key];
				}
				item.$states[key] = null;
				item.$errors[key] = null;
			}
		},
		boldSearchSubstring(input, str) {
			return input ? str.replace(new RegExp(`(${input})`, "ig"), "<strong>$1</strong>") : str;
		},
		getInputId(item, key) {
			return item.id ? `${key}_${item.id}` : key;
		},
		onTextInput(item, key, val, required) {
			const inputVal = typeof val === "string" ? val.trim() : val;
			item.$errors[key] =
				(required && !inputVal) || item[key] !== (inputVal || null)
					? inputVal.length > 1
						? ""
						: "Zadejte alespoň 2 znaky"
					: null;
			item.$states[key] = item.$errors[key] !== null ? (item.$errors[key] ? false : true) : null;
		},
		onTextBlur(item, key, required) {
			if (typeof item.$inputs[key] === "string") {
				const val = item.$inputs[key];
				item.$inputs[key] = item.$inputs[key].trim();
				if (val !== item.$inputs[key]) {
					this.onTextInput(item, key, item.$inputs[key], required);
				}
			}
		},
		onSelectInput(item, key, required) {
			item.$errors[key] =
				(required && !item.$inputs[key]) || item[key] !== (item.$inputs[key] || null)
					? required && !item.$inputs[key]
						? "Toto pole je povinné"
						: ""
					: null;
			item.$states[key] = item.$errors[key] !== null ? (item.$errors[key] ? false : true) : null;
		},
		modalClose(event, item) {
			// programaticke zavreni pres visible je bez triggeru
			if (event && event.trigger && this.itemChangedAndValid(item) !== 0) {
				event.preventDefault();
				if (!window.confirm("Opravdu zavřít formulář?")) {
					return;
				}
				this.$emit("hide");
			}
		},
		onRemoveOneToManyRelation(key, item, value, required) {
			// relations trader, placement, format
			this.item.$inputs[key] = null;
			if (!required && !this.item[key]) {
				this.item.$errors[key] = null;
				this.item.$states[key] = null;
				return;
			}
			this.item.$errors[key] = required ? "Toto pole je povinné" : "";
			this.item.$states[key] = this.item.$errors[key] ? false : true;
		},
		onAddOneToManyRelation(key, item, value) {
			// relations trader, format
			// bacha muze byt z aresu bez id (client, agency)
			this.item.$inputs[key] = value || null;
			if (isEqual(this.item[key], value)) {
				this.item.$errors[key] = null;
				this.item.$states[key] = null;
				return;
			}
			this.item.$errors[key] = "";
			this.item.$states[key] = true;
		},
		// stejne jako user.vue traders
		onAddManyToManyRelation(key, item, value) {
			if (!item.$inputs[key]) {
				item.$inputs[key] = [value];
			} else if (!item.$inputs[key].some((item) => value.id === item.id)) {
				item.$inputs[key].push(value);
			} else {
				return;
			}
			const changed = !isEqual(item[key], item.$inputs[key]);
			item.$errors.placements = changed ? "" : null;
			item.$states.placements = changed ? true : null;
		},
		onRemoveManyToManyRelation(key, item, value) {
			if (item.$inputs[key] && item.$inputs[key].some((item) => value.id === item.id)) {
				if (item.$inputs[key].length === 1) {
					item.$inputs[key] = null;
				} else {
					item.$inputs[key] = item.$inputs[key].filter((item) => item.id !== value.id);
				}
			} else {
				return;
			}
			const changed = !isEqual(item[key], item.$inputs[key]);
			item.$errors.placements = changed ? "" : null;
			item.$states.placements = changed ? true : null;
		},
		inputsDiff(inputs, originalItem) {
			const diffObj = {};
			let empty = true;
			for (const key in inputs) {
				// skip internals
				if (key.charAt(0) === "$") {
					continue;
				}
				const defaultVal = typeof originalItem[key] === "string" ? originalItem[key].trim() : originalItem[key];
				const inputVal = typeof inputs[key] === "string" ? inputs[key].trim() : inputs[key];
				if (!isEqual(inputVal, defaultVal)) {
					diffObj[key] = inputVal;
					empty = false;
				}
			}
			return !empty ? diffObj : null;
		},
		submit(apiUri, item, noMsg, callbackFc) {
			if (this.itemChangedAndValid(item, true) !== 1 || (!noMsg && !window.confirm("Opravdu uložit?"))) {
				return;
			}

			item.$diff = this.inputsDiff(item.$inputs, item);
			if (!item.$diff) {
				if (!noMsg) {
					this.$root.msg = {
						value: "Nic se nezměnilo",
						type: "text-warning",
					};
				}
				return;
			}

			const createOp = !item.id;
			return this.fetchApi(`/${apiUri}${item.id ? `/${item.id}` : ""}`, {
				method: createOp ? "POST" : "PATCH",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(item.$diff),
			})
				.then(async (result) => {
					Object.assign(item, item.id ? item.$diff : result);
					for (const key in item.$diff) {
						item.$states[key] = null;
						item.$inputs[key] = item[key];
						item.$errors[key] = null;
					}
					item.$diff = null;
					if (callbackFc) {
						await callbackFc();
					}
					return item;
				})
				.catch((err) => {
					console.error(err.message || err.statusText || err);
					for (const key in item.$diff) {
						if (item.$inputs[key] !== item[key]) {
							item.$states[key] = false;
							item.$errors[key] = err.message || err.statusText || err;
						}
					}
				});
		},
		async submitItem(apiUri, item, noMsg, callbackFc) {
			const createOp = !item.id;
			await this.submit(apiUri, item, noMsg, callbackFc);
			this.$emit(createOp ? "create" : "update", item);
		},
		deleteItem(apiUri, item, callbackFc, moveTo) {
			const fromName = item.alias || item.name || item.description;
			const toName = moveTo ? moveTo.alias || moveTo.name || moveTo.description : "";
			const msg = moveTo
				? `Opravdu převést kampaně subjektu "${fromName}" pod subjekt "${toName}"? Subjekt "${fromName}" bude smazán.`
				: `Opravdu smazat položku "${fromName}"?`;
			if (!window.confirm(msg)) {
				return;
			}
			return this.fetchApi(`/${apiUri}/${item.id}${moveTo ? `?moveTo=${moveTo.id}` : ""}`, {
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
				},
			})
				.then(async () => {
					if (callbackFc) {
						await callbackFc();
					}
					this.$emit("delete", moveTo);
				})
				.catch((err) => console.error(err.message || err.statusText || err));
		},
		searchModelProvider(apiUri, notID) {
			return async (searchStr) =>
				await this.fetchApi(`/${apiUri}?search=${encodeURI(searchStr)}`, null, false)
					.then((data) => {
						if (!data || !data.rows || !data.rows.length) {
							return [];
						}
						return data.rows.filter((item) => item.id !== notID);
					})
					.catch((err) => console.error(err.message || err.statusText || err));
		},
		itemChangedAndValid(item, submit) {
			let valid = true;
			let changed = false;
			for (const key in item.$inputs) {
				if (key === "$mediaplanFile" || key === "$invoiceFile") {
					const fileType = key === "$mediaplanFile" ? "mediaplan" : "invoice";
					if (!item.files || !item.files.some((f) => f.type === fileType)) {
						// $mediaplanFile je povinny jen pro tradera kdyz je objednavka
						if (
							submit &&
							!item.$inputs[key] &&
							key === "$mediaplanFile" &&
							!this.isAdmin &&
							item.$inputs.type === "order"
						) {
							item.$errors[key] = "Toto pole je povinné";
							item.$states[key] = false;
							valid = false;
						}
					}
				} else if (this.required.indexOf(key) !== -1) {
					// klient a agency se muzou vytvaret s kampani - nemaji jeste id
					if (!item.$inputs[key]) {
						if (submit && (!item.$errors[key] || item.$states[key])) {
							item.$errors[key] = "Toto pole je povinné.";
							item.$states[key] = false;
						}
						valid = false;
					}
				}

				// kvuli null vs empty string apod
				if ((item.$inputs[key] || item[key]) && !isEqual(item.$inputs[key], item[key])) {
					changed = true;
				}

				if (item.$states[key] === false || item.$errors[key]) {
					valid = false;
				}
			}
			return changed ? (valid ? 1 : -1) : 0;
		},
		onSelectedOption(item, option) {
			Object.assign(item.$inputs, option);
		},
		validateICO(item, value) {
			// kontrola validity ICO
			if (item.ico === (Number(item.$inputs.ico) || null)) {
				return null;
			}
			if (!/^\d{0,8}$/.test(value)) {
				return "IČO obsahuje nevalidní znaky.";
			} else if (value) {
				const numArr = new Array(8 - value.length).fill(0).concat(value.split("").map(Number));
				const ctrlSum = numArr.reduce((sum, n, i) => (i < 7 ? sum + n * (8 - i) : sum), 0);
				const ctrlRest = ctrlSum % 11;
				const ctrlNum = (ctrlRest ? (ctrlRest === 1 ? 0 : 11 - ctrlRest) : 1) | 0;
				if (numArr[7] !== ctrlNum) {
					return "IČO není validní.";
				}
			}
			return "";
		},
		validateDIC(item, value) {
			// kontrola validity DIC
			if (item.dic === value || (!item.dic && !value)) {
				return null;
			}
			if (!/^[a-zA-Z]{2}[0-9a-zA-Z]+$/.test(value)) {
				return "DIČ obsahuje nevalidní znaky.";
			}
			return "";
		},
	},
};
