<template>
	<div class="container-fluid">
		<div class="col-sm-8 col-lg-6 mx-auto">
			<h2>Registrace</h2>
			<form @submit.prevent="register">
				<div class="form-group row">
					<label class="col-xs-12 col-md-5 col-lg-4 col-form-label" for="usr">Email:</label>
					<div class="col-xs-12 col-md-7 col-lg-8">
						<b-form-input
							v-model="email"
							type="email"
							placeholder="Emailová adresa.."
							@input="
								emailError = email
									? /\S+@\S+\.\S+/.test(email)
										? ''
										: 'Nevalidní email'
									: 'Toto pole je povinné'
							"
							id="usr"
							:state="!emailError ? (email ? true : null) : false"
						>
						</b-form-input>
						<div class="invalid-tooltip truncate" v-if="emailError">{{ emailError }}</div>
					</div>
				</div>
				<div class="form-group row">
					<label class="col-xs-12 col-md-5 col-lg-4 col-form-label" for="pwd">Heslo</label>
					<div class="col-xs-12 col-md-7 col-lg-8">
						<div class="position-relative">
							<b-form-input
								v-model="password"
								type="password"
								placeholder="Heslo.."
								@input="checkPwd()"
								id="pwd"
								:state="!pwdError ? (password ? true : null) : false"
							>
							</b-form-input>
							<div class="invalid-tooltip truncate" v-if="pwdError">{{ pwdError }}</div>
						</div>
					</div>
				</div>
				<button
					class="btn btn-primary float-right"
					type="submit"
					:disabled="!!(!email || !password || pwdError || emailError)"
				>
					Registrovat
				</button>
			</form>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			email: "",
			password: "",
			pwdError: null,
			emailError: null,
		};
	},
	methods: {
		checkPwd() {
			if (!this.password) {
				this.pwdError = "Heslo je povinné";
			} else if (this.password.search(/\d/) === -1) {
				this.pwdError = "Heslo musí obsahovat znak 0-9.";
			} else if (this.password.search(/[a-z]/) === -1) {
				this.pwdError = "Heslo musí obsahovat znak a-z.";
			} else if (this.password.search(/[A-Z]/) === -1) {
				this.pwdError = "Heslo musí obsahovat znak A-Z.";
			} else if (this.password.search(/[^a-zA-Z0-9!@#$%^&*()_+]/) !== -1) {
				this.pwdError = "Heslo obsahuje nevalidní znak. Povolené: !@#$%^&*()_+";
			} else if (this.password.length < 8) {
				this.pwdError = "Heslo je příliš krátké (min 8)";
			} else if (this.password.length > 50) {
				this.pwdError = "Heslo je příliš dlouhé (max 50)";
			} else {
				this.pwdError = "";
			}
		},
		register() {
			let params = new URLSearchParams();
			params.set("username", this.email);
			params.set("password", this.password);

			this.fetchApi("/register", {
				method: "POST",
				body: params.toString(),
				credentials: "include",
				headers: {
					"Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
				},
			})
				.then((response) => {
					this.$router.replace("/login");
				})
				.catch((err) => {
					localStorage.removeItem("username");
					localStorage.removeItem("userId");
					localStorage.removeItem("userRole");
					this.emailError = err.message || err.statusText || err;
					return;
				});
		},
	},
};
</script>
