<template>
	<div class="container-fluid">
		<h2 v-if="!loading&&!placement" class="text-center">Stránka nenalezena</h2>
		<div v-if="placement">
			<div class="row">
				<div class="col">
					<h2 class="mb-3">{{ placement.description }}</h2>
				</div>
				<div class="col-auto">
					<span class="btn btn-sm btn-primary no-cursor py-1">
						kapacita 
						<span class="badge badge-tiscali">{{ getFormattedNumber(placement.week_plan_impression) }}</span>
					</span>
				</div>
			</div>
			<div class="row mb-2">
				<div class="form-group form-inline col-auto">
					<label class="smaller mr-2" :for="getInputId('year')">Rok:</label>
					<b-form-select v-model="yearFilter"
						:id="getInputId('year')"
						:options="yearOptions"
						@change="refreshTable"
						size="sm">
					</b-form-select>
				</div>
			</div>
		</div>
		<b-table ref="table"
			v-show="!!placement"
			stacked="md"
			:items="rowProvider"
			:fields="fields"
			:filter="filter"
			:sort-by.sync="sortBy"
			:sort-desc.sync="sortDesc"
			:tbody-class="'text-nowrap'"
			:tbody-tr-class="rowClass"
			:empty-text="'Žádné týdny k zobrazení.'"
			:empty-filtered-text="'Žádný týden nevyhovuje vybraným kritériím.'"
			@filtered="onFiltered"
			@row-clicked="toggleRowDetails"
			no-provider-paging
			no-provider-sorting
			no-sort-reset
		>
			<template v-slot:cell(number)="{ item }">
				W{{ item.number }} ({{ item.label }})
			</template>

			<template v-slot:head(orderImps)>
				<div class="badge badge-success">OBJ</div> Objednávky
			</template>
			<template v-slot:head(rezervationImps)>
				<div class="badge badge-tiscali">REZ</div> Rezervace
			</template>
		</b-table>
		<div class="text-center">
			<button type="button" class="btn btn-tiscali" v-if="showLimited" @click="showLimited = false">
				Zobrazit vše
			</button>
		</div>
		<placement-week-plan-table-modal
			ref="modal"
			v-if="modalItem"
			:week="modalItem"
			:placement="placement"
			:visible="modalVisible"
			@hide="hideModal($event)"
			@hidden="modalItem=null"
			@update="onRowChanged($event, 0)"
			@delete="onRowChanged($event, -1)"
			@create="onRowChanged($event, 1)">
		</placement-week-plan-table-modal>
	</div>
</template>

<script>
import PlacementWeekPlanTableModal from '../forms/placement_week_mtable.vue';
import inputMixin from '../mixins/input_mixin';
import tableMixin from '../mixins/table_mixin';

const currentDate = new Date();
const defaultParams = {
	sort: 'week',
	desc: 1,
	year: currentDate.getFullYear()
}

const filter = {
	year: {
		type: Number,
		validator: function(value) {
			// The value must match one of these strings
			return /^20\d{2}$/.test(value);
		},
		default: defaultParams.year
	},
	search: {
		type: String
	},
	id: {
		type: Number
	}
};

export default {
	components: {
		PlacementWeekPlanTableModal
	},
	mixins: [tableMixin, inputMixin],
	props: Object.assign({
		sort: {
			type: String,
			default: defaultParams.sort
		},
		desc: {
			type: Number,
			default: defaultParams.desc
		}
	}, filter),
	data () {
		const yearOptions = [];
		for (let y = 2020; y < (defaultParams.year + 2); y++) {
			yearOptions.push({
				text: y.toString(),
				value: y
			});
		}
		return Object.assign(this.tableData(filter), this.inputData(), {
			fields: [
				{ key: 'number', label: 'Týden', sortable: true},
				{ key: 'orderImps', label: 'Objednávky', thStyle: 'width: 150px', class: 'table-num-col', formatter: (value, key, item) => {
					return this.getFormattedNumber(value)||0;
				}},
				{ key: 'rezervationImps', label: 'Rezervace', thStyle: 'width: 150px', class: 'table-num-col', formatter: (value, key, item) => {
					return this.getFormattedNumber(value)||0;
				}},
				{ key: 'imps', label: 'Celkem imps', sortable: true, thStyle: 'width: 150px', class: 'table-num-col', formatter: (value, key, item) => {
					return this.getFormattedNumber(value)||0;
				}},
				{ key: 'impsLeft', label: 'Zbývá imps', sortable: true, thStyle: 'width: 150px', class: 'font-weight-bold table-num-col', formatter: (value, key, item) => {
					return this.getFormattedNumber(value)||0;
				}},
			],
			showLimited: Number(defaultParams.year) <= Number(this.year),
			yearOptions,
			apiUri: `placement/${this.$route.params.id}/plan`,
			defaultParams,
			placement: null
		});
	},
	methods: {
		async onRowChanged(plan, op) {
			switch (op) {
				case -1: {
					const delFromRel = this.placement.placements_campaigns.find(pc => pc.placement_week_plans.some(p => p.id === plan.id));
					delFromRel.placement_week_plans = delFromRel.placement_week_plans.filter(p => p.id !== plan.id);
					break;
				}
				case 0: {
					this.placement.placements_campaigns.forEach(pc => {
						const p = pc.placement_week_plans.find(p => p.id === plan.id);
						p && Object.assign(p, plan);
					});
					break;
				}
				case 1: {
					const addToRel = this.placement.placements_campaigns.find(pc => pc.id === plan.placements_campaigns_id);
					addToRel.placement_week_plans.push(plan);
					break;
				}
			}
			this.setWeeksContext(this.items);
			const obj = this.modalItem;
			this.modalItem = null;
			this.flashRow(obj);
		},
		rowClass(item, type) {
			if (type == 'row-details' || !item) return;

			let cls = '';
			if (item.$empty) {
				cls += 'half-opacity';
			} else {
				cls += 'cursor-pointer';
				if (item.impsLeft < 0) {
					cls += ' text-danger';
				}
			}
			if (this.showLimited && item.$hide) {
				cls += ' d-none';
			} else if (item.$weekPosition === 0) {
				cls += ' current-week'
			}
			return cls;
		},
		generateYearWeeks() {
			const year = this.year;
			const weeks = [];
			const firstYearDate = new Date(year, 0, 1);
			const firstDayNum = this.getWeekDay(firstYearDate.getDay());
			const firstWeekDate = new Date(firstYearDate)
			firstWeekDate.setDate(firstYearDate.getDate() + (firstDayNum < 4 ? -firstDayNum : 7 - firstDayNum));
			for (let i = 0; i < 54; i++) {
				const start = new Date(firstWeekDate);
				start.setDate(firstWeekDate.getDate() + (i*7));
				const end = new Date(firstWeekDate);
				end.setDate(firstWeekDate.getDate() + (i*7) + 6);
				if (i > 51 && firstYearDate.getFullYear() !== end.getFullYear()) {
					if (firstYearDate.getFullYear() !== start.getFullYear()) {
						// obe data jsou v dalsim roce - zahazujeme
						break;
					}
					const firstDayNextYearNum = this.getWeekDay((new Date(end.getFullYear(), 0, 1)).getDay());
					if (firstDayNextYearNum > 3) {
						// week overflows to next year but still belongs to this one
						weeks.push({ start, end })
					}
					break;
				}
				weeks.push({ start, end })
			}
			return weeks;
		},
		rowProvider(ctx) {
			this.loading = true;
			this.updateTableParams();
			let queryParams = new URLSearchParams(this.queryFilter).toString();
			queryParams = queryParams ? '?' + queryParams : '';
			return this.fetchApi(`/${this.apiUri}${queryParams}`)
				.then((data) => {
					if (!data) {
						throw data;
					}

					this.placement = data;

					const weeks = this.generateYearWeeks();
					this.setWeeksContext(weeks);
					this.items = weeks;
					this.loading = false;
					return this.items;
				})
				.catch((err) => {
					console.error(err)
					this.loading = false;
					this.placement = null;
					this.items = [];
					return this.items;
				})
		},
		setWeeksContext(weeks) {
			let currentWeekIndex = currentDate.getFullYear() !== this.year ? 0 : null;
			weeks.forEach((w,idx) => {
				w.number = idx + 1;
				w.label = `${w.start.getDate()}.${w.start.getMonth()+1} - ${w.end.getDate()}.${w.end.getMonth()+1}`;
				w.campaigns = [];
				w.plans = [];
				w.rezervationImps = 0;
				w.orderImps = 0;
				w.imps = 0;
				w.$empty = true;
				w.$weekPosition = w.end < currentDate ? -1 : (w.start > currentDate ? 1 : 0);
				if (w.$weekPosition === 0) {
					currentWeekIndex = idx;
				}
				w.$hide = currentWeekIndex === null || idx > (currentWeekIndex + 9);
				w.impsLeft = this.placement.week_plan_impression;
			});

			for (const rel of this.placement.placements_campaigns) {
				// kampane muzou jit napric roky a my resime jen jeden rok
				if (rel.campaign.week_year_from !== rel.campaign.week_year_to) {
					if (rel.campaign.week_year_from !== this.year.toString()) {
						rel.campaign.week_from = 1;
					}
					if (rel.campaign.week_year_to !== this.year.toString()) {
						rel.campaign.week_to = weeks.length;
					}
				}
				rel.campaign.$placements_campaigns_id = rel.id;
				rel.campaign.$dateFrom = rel.campaign.date_from ? new Date(rel.campaign.date_from + 'T12:00:00+00:00') : null;
				rel.campaign.$dateTo = rel.campaign.date_to ? new Date(rel.campaign.date_to + 'T12:00:00+00:00') : null;
				rel.campaign.$label = `id:${rel.campaign.id} | ${this.truncateStr(rel.campaign.client.name, 20)}`;
				if (rel.campaign.agency) {
					rel.campaign.$label += ' | ' + this.truncateStr(rel.campaign.agency.name, 20);
				}
				rel.campaign.$label += ' | ' + rel.campaign.trader.alias; 
				for (const plan of rel.placement_week_plans) {
					plan.$campaign = rel.campaign;
					plan.$dateFrom = new Date(plan.date_from + 'T12:00:00+00:00');
					plan.$dateTo = new Date(plan.date_to + 'T12:00:00+00:00');
					const startDay = this.getWeekDay(plan.$dateFrom.getDay());
					const endDay = this.getWeekDay(plan.$dateTo.getDay());
					plan.$leftOffset = (startDay/6)*100;
					plan.$rightOffset = (endDay/6)*100;
					weeks[plan.week-1].plans.push(plan);
					weeks[plan.week-1].imps += plan.impression;
					weeks[plan.week-1][rel.campaign.type+'Imps'] += plan.impression;
					weeks[plan.week-1].impsLeft -= plan.impression;
					weeks[plan.week-1].$empty = false;
				}
				for (let w = rel.campaign.week_from - 1; w < rel.campaign.week_to; w++) {
					if (!weeks[w].plans.some(p => p.placements_campaigns_id === rel.id)) {
						weeks[w].campaigns.push(rel.campaign);
						weeks[w].$empty = false;
					}
				}
			}
		},
		truncateStr(str, l=30) {
			return str.length > l ? str.substring(0, l-2).trim() + '..' : str;
		},
		getWeekDay(day) {
			// starting monday
			return day === 0 ? 6 : day - 1;
		},
		toggleRowDetails(item) {
			if (item.$empty) {
				return;
			}
			this.modalItem = item;
			this.modalVisible = true;
		},
	}
}
</script>
