<template>
	<div class="container-fluid">
		<div class="standalone-header" v-if="!parent">
			<h2 class="mb-3">Firmy
				<button type="button" class="btn p-0 mb-1 align-middle" v-if="!modalItem && isAdmin" @click="modalItem = {};modalVisible=true">
					<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20px" height="20px" x="0px" y="0px" viewBox="0 0 42 42" xml:space="preserve"><path fill="#62cb35" d="M37.059,16H26V4.941C26,2.224,23.718,0,21,0s-5,2.224-5,4.941V16H4.941C2.224,16,0,18.282,0,21s2.224,5,4.941,5H16v11.059 C16,39.776,18.282,42,21,42s5-2.224,5-4.941V26h11.059C39.776,26,42,23.718,42,21S39.776,16,37.059,16z"/></svg>
				</button>
			</h2>
		</div>
		<h4 class="mb-3" v-else>Firmy</h4>
		<!-- Main table element -->
		<div class="row mb-2">
			<div class="form-group col-md-10">
				<input type="text"
					class="form-control"
					v-model="searchFilter"
					placeholder="Hledat v názvu ..."
					id="search"
					v-on:change="onFilteredRefresh">
			</div>
		</div>
		<b-table ref="table"
			show-empty
			stacked="md"
			:items="rowProvider"
			:fields="fields"
			:current-page="currentPage"
			:per-page="perPage"
			:filter="filter"
			:sort-by.sync="sortBy"
			:sort-desc.sync="sortDesc"
			:tbody-class="'text-nowrap'"
			:tbody-tr-class="rowClass"
			:empty-text="'Žádní obchodníci k zobrazení.'"
			:empty-filtered-text="'Žádný obchodník nevyhovuje vybraným kritériím.'" 
			@filtered="onFiltered"
			no-sort-reset
		>
			<template v-slot:cell(name)="{item}">
				<div class="position-relative">
					<b-form-input v-model="item.$inputs.name"
						placeholder="Zadejte název.."
						:id="getInputId(item, 'name')"
						size="sm"
						:state="item.$states.name"
						@input="onNameInput(item, $event)"
						@focus="onToggleNameFocus(item, true)"
						@blur="onToggleNameFocus(item, false)"
						:required="true">
					</b-form-input>
					<div class="dropdown b-dropdown">
						<ul role="menu" tabindex="-1"
							:class="`dropdown-menu w-100 ${item.$nameFocused && item.$inputs.name && (item.$nameItems.length||item.$loading) ? ' show' : ''}`"
							@focus="onToggleNameFocus(item, true)"
							@blur="onToggleNameFocus(item, false)">
							<li role="presentation" class="text-center" v-if="!item.$nameItems.length||item.$loading">
								<b-spinner small variant="secondary" label="Načítám" v-if="item.$loading"></b-spinner>
								<span class="b-dropdown-text" v-else>Žádné výsledky</span>
							</li>
							<li role="presentation" class="small" v-show="!item.$loading" v-for="option in item.$nameItems" :key="option.ico">
								<button role="menuitem" type="button" class="dropdown-item" v-on:click="onSelectedOption(item, option)">
									<span class="truncate" v-html="boldSearchSubstring(item.$inputs.name, getFormatOption(option))"></span>
								</button>
							</li>
						</ul>
					</div>
					<div class="invalid-tooltip truncate" v-if="item.$errors.name">{{ item.$errors.name }}</div>
				</div>
			</template>
			<template v-slot:cell(ico)="{item}">
				<b-form-input v-model="item.$inputs.ico"
					size="sm"
					style="width: 125px;"
					placeholder="Zadejte IČO.."
					:id="getInputId(item, 'ico')"
					:state="item.$states.ico"
					@blur="checkUidExists(item, 'ico')"
					@input="uidValidator(item, $event, 'ico', validateICO)">
				</b-form-input>
				<div class="invalid-tooltip truncate" v-if="item.$errors.ico">{{ item.$errors.ico }}</div>
			</template>
			<template v-slot:cell(dic)="{item}">
				<b-form-input v-model="item.$inputs.dic"
					size="sm"
					style="width: 150px;"
					placeholder="Zadejte DIČ/VAT.."
					:id="getInputId(item, 'dic')"
					:state="item.$states.dic"
					@blur="checkUidExists(item, 'dic')"
					@input="uidValidator(item, $event, 'dic', validateDIC)">
				</b-form-input>
				<div class="invalid-tooltip truncate" v-if="item.$errors.dic">{{ item.$errors.dic }}</div>
			</template>
			<template v-slot:cell(address)="{item}">
				<b-form-input v-model="item.$inputs.address"
					size="sm"
					placeholder="Zadejte adresu.."
					:id="getInputId(item, 'address')"
					:state="item.$states.address"
					@input="onTextInput(item, 'address', $event)"
					@blur="onTextBlur(item, 'address')">
				</b-form-input>
				<div class="invalid-tooltip truncate" v-if="item.$errors.address">{{ item.$errors.address }}</div>
			</template>
			<template v-slot:cell(valid)="{item}">
				<b-form-checkbox v-model="item.$inputs.valid"
					class="success-checkbox d-inline pr-0"
					:id="getInputId(item, 'valid')"
					title="Správnost údajů ověřena (např. ičo)"
					@change="item.$errors.valid = !!item.valid !== !!item.$inputs.valid ? '' : null"
					size="lg">
				</b-form-checkbox>
			</template>
			<template v-slot:cell($btns)="{item}">
				<table-buttons :item="item"
					:api-uri="apiUri"
					:on-item-state="itemChangedAndValid"
					:loading="item.$loading"
					:reset-item="resetItemInputs"
					:remove-item="toggleRemoveItem"
					:submit-item="submitItem"
					:remove-enabled="isSuperAdmin">
				</table-buttons>
			</template>
			<template v-slot:table-busy>
				<div class="text-center text-secondary my-2">
					<b-spinner class="align-middle"></b-spinner>
					<strong>Načítám...</strong>
				</div>
			</template>
		</b-table>

		<div class="row" v-if="!parent">
			<div class="col-md-6">
				<b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="my-0" />
			</div>
		</div>

		<company-modal-form v-if="!parent && modalItem"
			:parent="modalItem"
			:visible="modalVisible"
			:remove-item="removeItem"
			@hide="hideModal($event)"
			@hidden="modalItem=null;removeItem=false"
			@update="onRowChanged($event)"
			@delete="onRowChanged($event, true)"
			@create="onRowChanged($event, true)">
		</company-modal-form>
	</div>
</template>

<script>
import CompanyModalForm from '../forms/company_mform.vue';
import companyMixin from '../mixins/company_mixin';
import inputMixin from '../mixins/input_mixin';
import tableMixin from '../mixins/table_mixin';

const defaultParams = {
	offset: 0,
	limit: 20,
	sort: 'name',
	desc: 0
}

const filter = {
	search: {
		type: String
	},
	id: {
		type: Number
	}
}

export default {
	components: {
		CompanyModalForm
	},
	mixins: [tableMixin, inputMixin, companyMixin],
	props: Object.assign(tableMixin.methods.getDefaultProps(defaultParams), filter),
	data () {
		return Object.assign(this.tableData(filter), this.inputData(), {
			fields: [
				{ key: 'ico', label: 'IČO', thStyle: 'width: 150px' },
				{ key: 'dic', label: 'DIČ', thStyle: 'width: 175px' },
				{ key: 'name', label: 'Název', sortable: !this.parent},
				{ key: 'address', label: 'Adresa', sortable: !this.parent},
				{ key: 'valid', label: 'OK', sortable: !this.parent, thStyle: 'width: 55px'},
				{ key: '$btns', label: '', thStyle: 'width: 105px'},
			],
			apiUri: 'company',
			required: ['name'],
			inputs: {
				name: null,
				address: null,
				valid: null,
				ico: null,
				dic: null
			},
			defaultParams
		});
	}
}
</script>
