import { isEqual } from "lodash";
import TableButtons from "../util/table-buttons.vue";

export default {
	components: {
		TableButtons,
	},
	props: {
		parent: Object,
	},
	computed: {
		offsetComputed() {
			return ((this.currentPage - 1) * this.perPage) | 0;
		},
		limitComputed() {
			return this.perPage;
		},
		descComputed() {
			return this.sortDesc ? 1 : 0;
		},
		sortComputed() {
			return this.sortBy;
		},
		searchComputed() {
			return this.search;
		},
	},
	methods: {
		getDefaultProps(defaultParams) {
			return {
				offset: {
					type: Number,
					default: defaultParams.offset,
				},
				limit: {
					type: Number,
					default: defaultParams.limit,
				},
				desc: {
					type: Number,
					default: defaultParams.desc,
				},
				sort: {
					type: String,
					default: defaultParams.sort,
				},
			};
		},
		tableData(filter) {
			return Object.keys(filter).reduce(
				(q, key) => {
					q[key + "Filter"] = this.$props[key];
					return q;
				},
				{
					currentPage: (this.offset / this.limit + 1) | 0,
					perPage: this.limit,
					totalRows: 0,
					removeItem: false,
					pageOptions: [10, 20, 50],
					loading: false,
					sortBy: this.sort,
					sortDesc: !!this.desc,
					filter: null,
					createForm: false,
					modalItem: null,
					modalVisible: false,
					apiUri: "",
					queryFilter: {},
					defaultParams: {},
					printing: false,
					printBackup: null,
					items: [],
				}
			);
		},
		async onRowChanged(obj, refreshTable, keepModal) {
			if (refreshTable) {
				this.$refs.table.$once("refreshed", this.flashRow.bind(this, obj));
				this.refreshTable();
				return;
			}
			if (this.modalItem && obj) {
				this.updateRow(this.modalItem, obj);
				if (!keepModal) {
					obj = this.modalItem;
					this.modalItem = null;
					this.flashRow(obj);
				}
			}
		},
		flashRow(obj) {
			if (!obj) {
				return;
			}
			obj = this.items.find((item) => item.id === obj.id);
			if (obj) {
				obj._rowVariant = "row-updated";
				setTimeout(() => (obj._rowVariant = ""), 3000);
			}
		},
		updateRow(item, newData) {
			Object.assign(item, newData);
		},
		updateTableParams() {
			if (this.parent || this.$root.print) {
				return;
			}

			const diff = {};
			for (const key in this.$props) {
				if (key === "parent") {
					continue;
				}
				if (key in this.defaultParams) {
					this.queryFilter[key] =
						key + "Computed" in this ? this[key + "Computed"] : this[key + "Filter"] || this[key];
					if (!isEqual(this.queryFilter[key], this.defaultParams[key])) {
						diff[key] = this.queryFilter[key];
					}
				} else if (this[key + "Filter"] || this.isNumber(this[key + "Filter"])) {
					this.queryFilter[key] = this[key + "Filter"];
					diff[key] = this.queryFilter[key];
				} else {
					delete this.queryFilter[key];
				}
			}

			this.$router.push({ query: diff }).catch((_) => {});
		},
		rowClass(item, type) {
			if (type == "row-details" || !item) return;

			let cls = "";

			if ("_showDetails" in item) {
				cls += "cursor-pointer";
			}
			return cls;
		},
		rowProvider(ctx) {
			if (this.printBackup) {
				this.totalRows = this.printBackup.count;
				this.items = this.printBackup.rows;
				this.$root.print = false;
				this.printBackup = null;
				return this.items;
			}
			this.updateTableParams();
			let queryParams = new URLSearchParams(this.queryFilter);
			if (this.$root.print) {
				this.printBackup = { count: this.totalRows, rows: this.items };
				queryParams.set("limit", "1000");
				queryParams.set("offset", "0");
			}
			queryParams = queryParams.toString() ? "?" + queryParams.toString() : "";
			return this.fetchApi(`/${this.apiUri}${queryParams}`)
				.then((data = {}) => {
					this.totalRows = data.count || 0;
					data.rows ??= [];
					this.onProviderData && this.onProviderData(data);

					if (!data.rows.length && this.currentPage > 1) {
						this.currentPage = (this.totalRows / this.perPage + 1) | 0;
					}

					data.rows.forEach((item) => this.setItemContext(item, this.inputs));
					this.items = data.rows;
					if (this.$root.print && this.printBackup) {
						setTimeout(this.generatePdf, 200);
					}
					return data.rows;
				})
				.catch((err) => {
					console.error(err.message || err.statusText || err);
					this.items = [];
					return [];
				});
		},
		onFilteredRefresh() {
			// debilita ve vue-bootstrap a co ridi refresh tabulky
			const wasFirstPage = this.currentPage === 1;
			this.onFiltered();
			if (wasFirstPage) {
				this.refreshTable();
			}
		},
		onFiltered() {
			this.totalRows = 0;
			this.currentPage = 1;
		},
		refreshTable() {
			this.modalItem = null;
			return this.$refs.table.refresh();
		},
		toggleRowDetails(item) {
			if (!this.$root.print) {
				this.modalItem = item;
				this.modalVisible = true;
			}
		},
		toggleRemoveItem(item) {
			if (!this.$root.print) {
				this.removeItem = true;
				this.modalItem = item;
				this.modalVisible = true;
			}
		},
		hideModal(e) {
			this.modalVisible = false;
		},
		handlePdfClick() {
			this.$root.print = true;
			this.refreshTable();
		},
		async generatePdf() {
			const html = document.documentElement.outerHTML;
			this.printing = true;

			try {
				const response = await this.fetchApi("/pdf", {
					method: "POST",
					headers: {
						"Content-Type": "text/html",
					},
					body: html,
				}).then((res) => res.arrayBuffer());

				const filename = `buying_${this.$route.name}_${window.location.search}.pdf`;
				if (typeof window.chrome !== "undefined" || window?.navigator?.userAgent.includes("Safari")) {
					// Chrome/safari version
					const blob = new Blob([response], { type: "application/pdf" });
					const link = document.createElement("a");
					link.href = window.URL.createObjectURL(blob);
					link.download = filename;
					link.click();
				} else if (typeof window?.navigator?.msSaveBlob !== "undefined") {
					// IE version
					const blob = new Blob([response], { type: "application/pdf" });
					window.navigator.msSaveBlob(blob, filename);
				} else {
					// Firefox version
					const file = new File([response], filename, { type: "application/force-download" });
					window.open(URL.createObjectURL(file));
				}
			} catch (err) {
				console.error(err);
			}
			this.printing = false;
			this.refreshTable();
		},
	},
};
