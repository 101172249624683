import { debounce } from "lodash";

export default {
	methods: {
		getAliasvalidator(item) {
			return val => {
				// kontrola validity aliasu
				const aliasState = /^[a-zA-Z0-9]{2,6}$/.test(val);
				if (aliasState === false) {
					item.$states.alias = aliasState;
					item.$errors.alias = "Nevalidní alias";
					return;
				}
				item.$errors.alias = item.$states.alias = null;

				if (item.alias !== item.$inputs.alias) {
					item.$loading = true;
					this.debouncedCheckAliasExists(item);
				}
			};
		},
		getName(item) {
			return `${item.alias || ""}${item.name ? ` (${item.name})` : ""}`;
		},
		debouncedCheckAliasExists: debounce(async function(item) {
			if (item.$states.alias !== false && item.alias !== item.$inputs.alias) {
				await this.fetchApi(`/trader?alias=${encodeURI(item.$inputs.alias)}`)
					.then(data => {
						if (data && data.rows && !data.rows.some(t => t.id === item.id)) {
							// unicode_ci skipuje pri porovnani diakritiku
							throw new Error(
								`Podobný alias už existuje("${data.rows[0].alias}"). Zvolte jiný alias nebo použijte existujicího obchodníka.`
							);
						}
						item.$states.description = true;
						item.$errors.description = "";
					})
					.catch(err => {
						item.$errors.alias = err.message || err.statusText || err;
						item.$states.alias = false;
						return;
					});
			}
			item.$loading = false;
		}, 1000)
	}
};
