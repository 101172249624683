<template>
  <div id="app" class="page-content" :style="$root.print ? 'pointer-events: none;' : ''">
    <nav class="navbar navbar-expand-xs navbar-light px-0 fixed-top">
      <div class="navbar-top w-100 bg-white">
        <div class="navbar-container container-fluid">
          <router-link class="navbar-brand py-0" :to="{name:'campaign'}">
            <img :src="$root.logo" height="30" alt="">
          </router-link>
          <div class="right-nav">
            <strong style="font-size: 18px;">Buying</strong>
            <button class="navbar-toggler d-print-none" type="button"
              aria-controls="nav-content"
              :aria-expanded="!!$root.showNav"
              aria-label="Navigace"
              @click="$root.showNav = !$root.showNav">
              <span class="navbar-toggler-icon"></span>
            </button>
          </div>
        </div>
      </div>
      <div class="navbar-bottom w-100 d-print-none">
        <div class="container-fluid px-0">
          <div class="collapse px-3 py-2 bg-white navbar-collapse" style="box-shadow: 0 5px 5px rgba(0,0,0,.3)" id="nav-content" v-bind:class="$root.showNav ? 'show' : ''">
            <div class="navbar-nav mr-auto">
              <router-link v-if="!loggedIn()" class="nav-item nav-link" :to="{name:'register'}">Registrace</router-link>
              <router-link v-if="hasAnyRights()" class="nav-item nav-link" :to="{name:'campaign'}">Kampaně</router-link>
              <router-link v-if="hasAdminRights()" class="nav-link nav-item" :to="{name:'trader'}">Obchodníci</router-link>
              <router-link v-if="hasAdminRights()" class="nav-item nav-link" :to="{name:'format'}">Formáty</router-link>
              <router-link v-if="hasAdminRights()" class="nav-item nav-link" :to="{name:'placement'}">Média</router-link>
              <router-link v-if="hasAdminRights()" class="nav-item nav-link" :to="{name:'company'}">Firmy</router-link>
              <router-link v-if="hasAdminRights()" class="nav-item nav-link" :to="{name:'user'}">Uživatelé</router-link>
              <div v-if="$root.plannedPlacements && $root.plannedPlacements.length" class="dropdown-divider"></div>
              <router-link
                v-for="p in ($root.plannedPlacements||[])"
                class="nav-item nav-link"
                :key="p.id"
                :to="{name:'placementWeeks', params: { id: p.id }}">
                {{ p.description }}
              </router-link>
              <div class="dropdown-divider"></div>
              <router-link v-if="loggedIn()" class="nav-item nav-link" to="/logout">Odhlásit <small>({{ $root.username }})</small></router-link>
              <router-link v-else class="nav-item nav-link" :to="{name:'login'}">Login</router-link>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <div v-if="$root.msg && toggleModal()" class="notification-msg d-flex justify-content-center align-items-center">
      <span
        v-bind:class="$root.msg.type"
        class="alert py-1 px-2">
        {{ $root.msg.value }}
      </span>
    </div>

    <template v-if="$route.matched.length && loggedIn() && !hasAnyRights()">
      <div class="container-fluid"><h2 class="text-center text-danger">Požádejte admina o přístup</h2></div>
    </template>
    <template v-else-if="$route.matched.length"><router-view :key="$route.path"></router-view></template>
    <template v-else><div class="container-fluid"><h2 class="text-center">Stránka nenalezena</h2></div></template>
    <div class="footer d-print-none mt-3">
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    toggleModal() {
      return setTimeout(_ => {this.$root.msg = null},5000)
    },
    hasAnyRights() {
      return this.hasMinRights('ghost')
    },
    hasAdminRights() {
      return this.hasMinRights('admin')
    },
    hasSuperAdminRights() {
      return this.hasMinRights('superadmin')
    },
  }
}
</script>
